.cart-table-item {
  .cart-table-item__info {
    height: 100%;
    padding: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
  }

  .cart-table-item__delete-btn {
    align-self: center;

    color: var(--grey100);
  }

  .cart-table-item__delete-btn-spinner {
    color: inherit;
  }
}

@media (max-width: 1024px) {
  .add-on-table-item.cart-table-item {
    max-height: 75px;
    grid-template-rows: auto auto;
    grid-template-columns: 100px 1fr;

    .add-on-table-item__info {
      margin-top: 8px;
    }

    .add-on-table-item__title {
      font-size: 16px;
      line-height: 18px;
      min-height: 18px;
      -webkit-line-clamp: 1;
    }

    .add-on-table-item__categories,
    .add-on-table-item__publisher {
      display: none;
    }

    .cart-table-item__info {
      padding: 0;
      gap: 8px;

      .table-item-price {
        grid-template-rows: 1fr auto;
      }
    }
  }
}
