.dialog-uploading__content.dialog-uploading__content {
  background-color: var(--grey700);
  color: var(--grey100);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 30px;
  overflow: hidden;
}

.dialog-package__content {
  background-color: var(--grey700);
  color: var(--grey100);
  display: flex;
  flex-direction: column;
  padding: 32px 30px;
  overflow: hidden;

  h2 {
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 25px;
  }

  .richtext__textbox::-webkit-scrollbar {
    width: 8px;
    background-color: var(--grey700);
  }
  .richtext__textbox::-webkit-scrollbar-thumb {
    background: var(--green600);
  }

  .richtext__textbox {
    max-height: 160px;
    overflow-y: scroll;
    scrollbar-color: var(--green600) var(--grey700);
  }
}

.dialog-uploading__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
}

.dialog-uploading__button {
  width: 150px;

  &.dialog-uploading__button--white {
    background-color: var(--grey200);
    color: var(--grey700);

    &:hover,
    &:focus {
      background-color: var(--grey300);
    }
  }
}

.dialog--package {
  .MuiPaper-root.MuiPaper-root {
    max-width: 375px;
  }
}

.dialog-uploading__actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 30px;
}

.dialog-package__button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
}

.dialog-package__button--submit {
  width: 195px;
  height: 48px;
}

.add-package-file-container {
  display: flex;
  gap: 20px;
}

.dialog-package__label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--grey300);
  border-radius: 0;
  color: var(--grey900);
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  transition: 0.2s;
  width: 168px;
  gap: 8px;
  height: 40px;
  line-height: 21px;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--grey200);
  }
}