.add-on-section--edit-price.add-on-section--edit-price {
  margin-top: 84px;
  margin-bottom: 40px;
  flex-direction: column;
  padding-top: 0;
}

.add-on-info-edit__spinner {
  align-self: stretch;
  display: flex;
  justify-content: center;
}

.add-on-info-edit__form {
  width: 100%;
}

.add-on-info-edit__button.add-on-info-edit__button {
  width: 180px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green600);
  color: var(--white);
  font-weight: 700;
  font-size: 20px;
  gap: 10px;
  border-radius: 0;
  transition: 0.2s;
  text-transform: uppercase;
  margin-left: auto;

  &:hover,
  &:focus {
    background-color: var(--green700);
  }

  &:disabled {
    background-color: var(--grey300);
  }
}
