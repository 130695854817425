.add-on-list__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  .swiper-button-prev {
    left: -5px;
  }
  .swiper-button-next {
    right: -5px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: var(--grey400);

    &::after {
      font-size: 28px;
    }

    &:hover,
    &:focus {
      color: var(--grey200);
    }
  }
  .swiper {
    padding-bottom: 30px;
  }

  .swiper-pagination.swiper-pagination {
    bottom: 0;
  }

  .swiper {
    padding-right: 30px;
    padding-left: 30px;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet {
    background-color: var(--grey400);
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }
}

.publisher-add-on-list__container {
  align-items: center;
}

.add-on-list__list-container {
  width: 100%;
}

.add-on-list__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  gap: 30px;
  justify-items: stretch;
  align-items: stretch;
  width: 100%;
}

.publisher-add-on-list__list {
  display: flex;
}

.add-on-list__item--slider {
  .add-on-card_wrapper {
    width: 240px;
  }
}

.add-on-list__loading-container {
  display: flex;
  min-width: 600px;
  color: var(--grey400);
  justify-content: center;
  margin-top: 50px;
}

.add-on-list__no-add-ons {
  color: var(--grey400);
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  .add-on-list__list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .add-on-list__item {
    width: 240px;
  }

  .add-on-list__loading-container {
    min-width: 100%;
  }
}