.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--grey900);
  position: relative;
  // overflow hidden makes position sticky impossible
  // overflow-x: hidden;
}

.content-container {
  //max-width: 1920px;
  margin: 0 auto;
}

.content-wrapper__children {
  //max-width: 1920px;
  margin: 0 15px;
}

.content-container--main {
  //padding-top: 30px;
  padding-bottom: 50px;
}

.content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // min-height: calc(100vh - 336px);
  background-color: var(--grey900);
}

.content-wrapper--loading {
  align-items: center;
  justify-content: center;
}

.content-wrapper__loading {
  color: var(--grey100);
}
