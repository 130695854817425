.add-on-page {
  margin: 0 15px;
  display: flex;
  justify-content: center;
  color: var(--grey100);
  background-color: var(--grey900);
}

.add-on-page__add-on {
  flex-basis: 1480px;
  // display: flex;
}

.add-on {
  padding: 60px 0;

  @media (max-width: 1024px) {
    padding-top: 16px;
  }
}

.add-on__main-content {
  display: flex;
  gap: 52px;
}

.add-on__version-section {
  flex-basis: 864px;
  flex-grow: 0;
  flex-shrink: 3;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

.add-on__version-select {
  display: flex;
  align-items: center;
  gap: 8px;

  .MuiFormLabel-root {
    color: inherit;
  }

  .MuiInputBase-root {
    border-color: transparent;
  }

  .MuiInputBase-input {
    padding: 6px 8px;
    line-height: 24px;
    background-color: var(--grey700);

    @media (max-width: 1024px) {
      min-width: 0;
    }
  }
}

.add-on__media {
  margin-bottom: 20px;
}

.add-on__base-info {
  flex-basis: 564px;
  flex-grow: 1;
  flex-shrink: 2;
  display: flex;
  flex-direction: column;
}

.add-on-tabs__tabs {
  gap: 40px;
  margin-bottom: 34px;
  padding: 0 16px;
  border-bottom: 1px solid var(--grey300);

  & .MuiTabs-flexContainer {
    gap: 40px;
  }

  & .add-on-tabs__tab {
    padding-left: 0;
    padding-right: 0;
    min-width: auto;

    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0;
    text-transform: none;
    color: var(--grey500);

    &:hover {
      color: var(--grey300);
    }

    &.Mui-selected {
      color: white;
    }
  }

  .MuiTabs-indicator {
    height: 4px;
    background-color: var(--green600);
  }
}

.add-on-tabs__content-container {
  padding-left: 0;
  // padding-left: 16px;
}

.add-on-page__related {
  padding: 32px 0 96px;
  background-color: var(--grey800);
}

.related-add-ons {
  display: flex;
  justify-content: center;
}

.related-add-ons__wrapper {
  flex-grow: 1;
  max-width: 1296px;
  margin: 0 10px;
}

.related-add-ons__heading {
  margin-bottom: 24px;

  font-weight: 500;
  font-size: 36px;
  color: var(--grey200);
}

.related-add-ons__list {
  display: flex;
  gap: 24px;
}

@media (max-width: 1024px) {
  .add-on__media {
    display: none;
  }

  .add-on__main-content--add-on-page {
    flex-direction: column;

    margin-bottom: 10px;
    .add-on__version-section {
      flex-basis: auto;
      order: 1;
    }
  }
}
