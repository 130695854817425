.unigine-select {
  font-size: 18px;
  color: var(--grey300);

  &.MuiInputBase-root {
    border: 1px solid var(--grey500);
    border-radius: 0;

    &.Mui-focused {
      .MuiInputBase-input {
        outline: none;
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }

  .MuiInputBase-input {
    min-width: 180px;
    padding: 8px;
    color: var(--grey300);
  }

  .MuiSelect-icon {
    color: var(--grey400);
  }
}
