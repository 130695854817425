.dialog--confirm-dialog {
  .MuiPaper-root.MuiPaper-root {
    max-width: 375px;
  }  
}

.dialog-confirm__content.dialog-confirm__content {
  background-color: var(--grey700);
  color: var(--grey100);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px 32px;
  overflow: hidden;
}

.dialog-confirm__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 25px;
}

.dialog-confirm__button {
  width: 150px;

  &.dialog-confirm__button--white {
    background-color: var(--grey200);
    color: var(--grey700);

    &:hover,
    &:focus {
      background-color: var(--grey300);
    }
  }
}

.dialog-confirm__actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
