// .add-on-section-wrapper {
//   padding-top: 38px;
// }

@media only screen and (max-width: 1609px) {
  .add-on-section__title.add-on-section__title {
    max-width: 750px;
  }
}

.add-on-section__title {
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  color: var(--grey100);
  text-transform: uppercase;
  margin-bottom: 0;
  margin-right: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 1000px;

  a {
    color: var(--grey100);
    text-decoration: none;
  }
}

.add-on-section__edit-link.add-on-section__edit-link {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green600);
  border-radius: 50%;
  margin-left: 40px;
  position: relative;
  top: -3px;
  min-width: initial;

  img {
    width: 20px;
    height: 20px;
    position: relative;
    left: -2px;
    top: 2px;
  }
}

.add-on-section__price-container {
  position: relative;
}

.add-on-section__price--line {
  position: absolute;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  text-decoration-line: line-through;
  color: var(--grey400);
  top: -26px;
  right: 0;
  white-space: nowrap;
}

.add-on-section__price {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: var(--grey100);
  white-space: nowrap;
}

.add-on-section__price--free {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: var(--green600);
  white-space: nowrap;
}

.add-on-section__price-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.add-on-section__title-wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
  margin-bottom: 55px;
  padding-left: 32px;
  padding-right: 22px;
  padding-bottom: 15px;
}

.add-on-section__version-container {
  margin-bottom: 20px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;

  .version-control {
    margin-right: auto;
  }

  .sort-control {
    gap: 18px;
  }

  .sort-control .MuiInputLabel-root {
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: var(--grey300);
  }
}

.add-on-section__publishing-tip {
  min-height: 60px;
}

.add-on-section__actions {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.add-on-section__actions--package--admin {
    flex-direction: row;
    align-items: flex-end;
  }
}

.add-on-section__action-button.add-on-section__action-button {
  width: 188px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green600);
  color: var(--white);
  font-weight: 700;
  font-size: 20px;
  gap: 10px;
  border-radius: 0;
  transition: 0.2s;
  text-transform: none;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--green700);
  }

  &:disabled {
    background-color: var(--grey300);
  }
}

.add-on-section {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
}

.add-on-section--loading {
  justify-content: center;
}

.add-on-section__action-button.add-on-section__action-button--edit-content {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  gap: 5px;
  height: 32px;
  text-transform: none;
  text-decoration: none;
}

.add-on-section__action-button.add-on-section__action-button--cancel-edit {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  gap: 5px;
  height: 32px;

  color: var(--black);
  background-color: var(--grey200);

  &:hover,
  &.Mui-disabled {
    background-color: var(--grey300);
  }
}

.add-on-section__add-on-wrapper {
  background-color: var(--grey700);
}

.add-on-section__add-on-actions-container {
  display: flex;
  margin-left: auto;
  margin-right: 22px;
  align-items: center;
  gap: 16px;
}

.add-on-section__add-on-top-container {
  display: flex;
  gap: 20px;
}

.add-on-section__status-tip {
  margin-top: 12px;
}

.add-on-section__add-on-action {
  flex-shrink: 0;
  width: 188px;
  height: 48px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  &.add-on-section__add-on-action--archive {
    background-color: var(--grey200);
    color: var(--grey900);

    &:hover,
    &:focus {
      background-color: var(--grey300);
    }

    &:active {
      background-color: var(--grey200);
    }
  }

  &.add-on-section__add-on-action--disabled {
    background-color: var(--grey300) !important;
    color: var(--white) !important;
    cursor: default;
  }
}

.add-on-section__checkbox-container {
  display: flex;

  .checkbox-group__item {
    color: var(--grey400);
  }
}

.add-on-section__action-container {
  display: flex;
  // margin-left: auto;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}

.add-on-section__error--text {
  font-size: 14px;
  color: var(--error);
}

.add-on-section__buttons-container {
  display: flex;
  gap: 24px;
}

.add-on-section__reject-button.add-on-section__reject-button {
  background-color: var(--orange700);

  &:hover,
  &:focus {
    background-color: var(--orange800);
  }
}

.add-on-section__link-wrapper {
  margin-top: 15px;
  color: var(--grey400);
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  text-transform: uppercase;

  a {
    color: var(--grey400);
    text-decoration: none;
    transition: 0.2s;

    &:hover,
    &:focus {
      color: var(--grey300);
    }
  }
}