.review-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.review-list__sort-container {
  display: flex;

  .unigine-select {
    width: 100px;
  }
}

.review-list__items {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.review-list__pagination {
  align-self: center;
}
