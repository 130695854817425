.publisher-link {
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--grey500);

  img {
    border-radius: 50%;
  }
}

.publisher-link__image {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-right: 12px;
}

.publisher-link__skeleton {
  margin-right: 12px;
}
