.price-filter__input {
  .MuiInputBase-root {
    color: var(--grey500);

    .MuiInputBase-input {
      max-width: 80px;
      padding: 3px 8px;

      border-color: currentColor;
    }

    .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
      border-color: currentColor;
      border-radius: 0;
    }

    &.Mui-focused:not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
        border-color: var(--green600);
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
        border-color: var(--errorBg);
      }
    }
  }
}

.price-radio-group {
  .MuiFormControlLabel-root:last-child {
    align-items: flex-start;
    .MuiButtonBase-root {
      top: 5px;
    }
  }

  .helper-text {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
  }
}
