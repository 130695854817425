.add-on-version-status__circle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--draft);
  position: relative;
  top: 2px;

  &.add-on-version-status__circle--draft {
    background-color: var(--draft);
  }

  &.add-on-version-status__circle--moderation {
    background-color: var(--moderation);
  }

  &.add-on-version-status__circle--published {
    background-color: var(--published);
  }  

  &.add-on-version-status__circle--archived {
    background-color: var(--archived);
  }

  &.add-on-version-status__circle--banned {
    background-color: var(--banned);
  }

  &.add-on-version-status__circle--error {
    background-color: var(--error);
  }

  &.add-on-version-status__circle--revision {
    background-color: var(--revision);
  }
}

.add-on-version-status__text {
  white-space: nowrap;
  line-height: 19px;
}

@media (max-width: 1609px) {
  .main--publisher-panel.main--fixed-sidebar .add-on-version-status__description {
    display: none;
  }
}