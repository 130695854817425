.add-on-description {
  padding-left: 16px;
}

.add-on-description__version {
  color: var(--grey100);
}

.add-on-description__tags-container {
  margin-top: 40px;
  max-width: 752px;
}

.add-on-description__tags-title {
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.add-on-description__tags-list {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.add-on-description__tags-item {
  display: flex;
  justify-content: center;
  align-items: center;
  .tag {
    text-decoration: none;
    padding: 2px 8px;
    transition: 0.2s;

    &:hover,
    &:focus {
      color: var(--green600);
      border-color: var(--green600);
    }
  }
}

.add-on-description__text {
  color: var(--grey300);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;

  word-wrap: break-word;

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  del {
    text-decoration: line-through;
  }

  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--green600);
  }

  ul,
  ol {
    margin-bottom: 16px;
    padding-left: 32px;
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }
}

@media (max-width: 1024px) {
  .data-list.add-on-description__datalist {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .data-list-item__content {
      margin-left: 32px;
      margin-bottom: 8px;
    }
  }
}
