.dialog-media__content.dialog-media__content {
  padding: 0;
  display: flex;
  align-items: center;
  background-color: var(--grey700);
  color: var(--grey100);
  overflow: hidden;

  &.dialog-media__content--form {
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    min-height: 460px;
  }

  &.dialog-media__content--confirm {
    padding: 32px;
    flex-direction: column;
    min-height: 170px;
  }

  h2 {
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.25px;
  }
}

.dialog-media__image-preview-container {
  flex-shrink: 0;
  display: flex;
  width: 112px;
  height: 63px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 30px;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.dialog-media__image {
  max-width: 100%;
  max-height: 100%;
}

.dialog-media__media-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--grey100);

  &:last-child {
    margin-bottom: 0;
  }
}

.dialog-media__input--file {
  display: none;
}

.dialog-media__label--file {
  background-color: var(--green600);
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;
  width: 188px;
  height: 63px;
}

.dialog-media__container {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--fg1 {
    flex-grow: 1;
  }
}

.dialog-media__image-size {
  margin-left: auto;
  width: 15%;
}

.dialog-media__image-name {
  margin-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 1px;
  width: 70%;
}

.dialog-media__button.dialog-media__button {
  width: 188px;
  height: 63px;
  display: flex;
  align-items: center;
  text-transform: none;
  justify-content: center;
  background-color: var(--green600);
  color: var(--white);
  font-weight: 700;
  font-size: 18px;
  gap: 10px;
  border-radius: 0;
  transition: 0.2s;

  &:focus,
  &:hover {
    background-color: var(--green600);
  }

  &--right {
    margin-left: auto;
  }

  &--confirm {
    width: 150px;
    height: 48px;
  }
}

.add-on-media__buttons-container {
  display: flex;

  &--mta {
    margin-top: auto;
  }
}

.dialog-media__media-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--grey900);
}

.dialog-media__media-list::-webkit-scrollbar-thumb {
  background: var(--green600);
}

.dialog-media__media-list {
  max-height: 320px;
  height: 320px;
  overflow-y: auto;
  margin-bottom: 20px;
  scrollbar-color: var(--green600) var(--grey900);
  scrollbar-width: thin;
}

.dialog-media__input--text.dialog-media__input--text {
  width: 100%;
  color: var(--grey100);

  label {
    color: var(--grey500);
    &.Mui-focused {
      color: var(--grey100);
    }
  }

  input {
    color: var(--grey100);

    &:focus + fieldset,
    &:hover + fieldset {
      border-color: var(--grey100);
    }
  }

  fieldset {
    border-radius: 0;
    border-color: var(--grey500);
  }
}

.dialog-media__form--video {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dialog-media__title {
  margin-bottom: 20px;
}

.dialog-media__text {
  line-height: 19px;
  text-align: center;
  color: var(--grey100);
  margin-bottom: 25px;
}

.dialog-media__iframe {
  flex-shrink: 0;
  margin-right: 25px;
}

.dialog-media__video-description {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.dialog-media__video-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.dialog-media__video-channel {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: auto;
}

.dialog__delete-chosen-image.dialog__delete-chosen-image {
  color: var(--grey400);

  &:focus,
  &:hover,
  &:active {
    color: var(--white);
  }
}

.dialog-media__error {
  font-weight: 400;
  font-size: 12px;
  color: var(--errorBg);
  margin-bottom: 10px;
}

.dialog-media__icon {
  margin-left: 40px;
}

.dialog-media__button-disabled.dialog-media__button-disabled {
  background-color: var(--grey300);
}

.dialog-media__margin-top {
  margin-top: 22px;
}

.dialog-media__buttons-container {
  display: flex;
  gap: 12px;
}
