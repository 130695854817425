.sidebar {
  width: 60px;
  background-color: var(--grey700);
  transition: top 0s;
  transition: width 0.2s;
  flex-shrink: 0;
  z-index: 4;
  position: fixed;
  top: 60px;
  bottom: 260px;
  height: 100%;

  &.sidebar--fixed {
    width: 300px;
    position: relative;
    top: 0;
    height: initial;

    .sidebar__sticky-container {
      position: sticky;
      top: 60px;
      min-height: 420px;
      height: calc(100vh - 60px - 270px);
    }
  }

  &.sidebar--hover {
    width: 300px;
  }

  &.sidebar--fixed.sidebar--hover {
    position: relative;
    top: 0;
  }
}

.sidebar__header {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
}

.sidebar__menu-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: var(--grey100);
  display: none;
}

.sidebar__nav-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  //padding: 8px 0px 8px 16px;
  height: 45px;
  span {
    margin-left: 16px;
  }
}

.sidebar__toggle-btn.sidebar__toggle-btn {
  color: var(--grey100);
  height: 60px;
  padding: 0px 16px;
  display: none;
}

.sidebar__nav-list {
  width: 100%;
}

.sidebar__link {
  text-decoration: none;
  color: var(--grey100);
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  fill: var(--grey300);
  color: var(--grey300);

  svg {
    flex-shrink: 0;
  }

  &:hover {
    background-color: var(--grey800);
  }

  &.sidebar__link--active {
    background-color: var(--grey900);
    fill: var(--grey100);
    color: white;
  }
}

.sidebar__link .sidebar__stroke-star {
  stroke: var(--grey300);
}

.sidebar__link--active .sidebar__stroke-star {
  stroke: var(--grey100);
}

.sidebar__link-text {
  display: none;
}

.sidebar--hover .sidebar__link-text,
.sidebar--fixed .sidebar__link-text {
  display: unset;
  animation: 0.2s fadeIn;
  animation-fill-mode: forwards;

  visibility: hidden;
}

.sidebar--hover .sidebar__menu-title,
.sidebar--fixed .sidebar__menu-title {
  display: unset;
  animation: 0.2s fadeIn;
  animation-fill-mode: forwards;

  visibility: hidden;
}

.sidebar--hover .sidebar__toggle-btn,
.sidebar--fixed .sidebar__toggle-btn {
  display: unset;
  animation: 0.2s fadeIn;
  animation-fill-mode: forwards;

  visibility: hidden;
}

// .sidebar__sticky-container {
//   position: sticky;
//   top: 60px;
//   height: 100vh;
// }

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
