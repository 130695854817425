.ordered-item__info {
  height: 100%;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 54px;
}

.ordered-item__details-link.ordered-item__details-link {
  flex-basis: 240px;
  padding: 16px;

  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  color: var(--white);

  background-color: var(--green600);
  border: none;
  border-radius: 0;

  &:hover {
    background-color: var(--green700);
  }

  &:active {
    background-color: var(--green800);
  }
}
