.refund-result-page {
  margin-top: 68px;
}

.refund-result-title {
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: var(--grey200);
}

.refund-result-text {
  margin-top: 24px;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: var(--grey100);

  a {
    color: var(--green600);
  }
}