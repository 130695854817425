.checkbox-unigine.MuiCheckbox-root {
  padding: 4px;
}

.checkbox-unigine__icon {
  display: flex;
  justify-content: center;
  align-items: center;

  .checkbox-unigine__outline {
    width: 16px;
    height: 16px;
    border: 1px solid var(--grey600);
  }

  .checkbox-unigine__fill {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;

    width: 8px;
    height: 8px;

    background-color: transparent;

    transform: translate(-50%, -50%);
  }

  &.checkbox-unigine__icon--checked {
    .checkbox-unigine__outline {
      border-color: var(--green600);
    }

    .checkbox-unigine__fill {
      background-color: var(--green600);
    }
  }

  &.checkbox-unigine__icon--disabled {
    .checkbox-unigine__outline {
      border-color: var(--grey700);
    }

    .checkbox-unigine__fill {
      background-color: var(--grey700);
    }
  }

  & + .MuiFormControlLabel-label.Mui-disabled.Mui-disabled {
    color: var(--grey700);
  }

  &.checkbox-unigine__icon--indeterminate {
    .checkbox-unigine__outline {
      border-color: var(--grey600);
    }

    .checkbox-unigine__fill {
      background-color: var(--grey600);
      width: 8px;
      height: 4px;
    }
  }
}

.MuiCheckbox-root:hover:not(.Mui-checked) {
  .checkbox-unigine__outline {
    border: 1px solid var(--grey500);
  }
}

.Mui-disabled {
  .checkbox-unigine__icon {
    .checkbox-unigine__fill {
      background-color: var(--grey700);
    }
    .checkbox-unigine__outline {
      border-color: var(--grey700);
    }
  }
}
