.platform-poster {
  width: 150px;
  height: 60px;
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--grey800);
  text-transform: uppercase;
  padding-left: 18px;
}

.platform-poster--cross {
  background-color: var(--crossposter);
}

.platform-poster--windows {
  background-color: var(--windowsposter);
}

.platform-poster--linux {
  background-color: var(--linuxposter);
}