.header {
  //background-color: var(--black);
  //color: var(--white);
  //padding: 0 15px;
  position: sticky;
  top: 0;
  z-index: 5;
}

.header__top-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 15px;
  height: 60px;
  background-color: var(--white);
}

.header__profile {
  display: flex;
}

.header__button.header__button {
  color: var(--grey900);
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.header__user-name {
  margin-right: 15px;
  font-size: 18px;
  font-weight: 400;
}

.header__logo {
  display: flex;
  width: 440px;
}

.logo__link {
  display: flex;
  flex-wrap: nowrap;
  min-width: 200px;
  text-transform: none;
  text-decoration: none;
  font-weight: 400;
  font-size: 26px;
  color: var(--green700);
}

.header__menu-popup {
  .MuiMenu-paper.MuiMenu-paper {
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0;
    background-color: var(--grey700);
    color: var(--grey100);
  }

  .MuiListItemIcon-root.MuiListItemIcon-root {
    color: var(--grey100);
  }

  .MuiDivider-root.MuiDivider-root {
    border-color: var(--grey100);
  }
}

.menu__link {
  text-decoration: none;
  color: inherit;
}

.menu__link--mb > li {
  margin-bottom: 8px;
}

.menu__link--mt > li {
  margin-top: 8px;
}

.logo__unigine {
  margin-right: 26px;
}

.header__experimental-notice {
  position: absolute;
  left: 15px;
  top: 0;
  width: 182px;

  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);

  background-color: var(--black);
  pointer-events: none;
}

.add-on-store-name {
  padding-left: 26px;
}

.header__bottom-container {
  padding: 23px 15px;
  display: flex;
  align-items: center;
  background-color: var(--grey800);
  height: 64px;
  //max-width: 1920px;

  &--pinned {
    position: sticky;
    top: 60px;
    z-index: 10;
  }
}

.header__navigation {
  display: flex;
}

.navigation--bottom {
  margin-right: 50px;
}

.navigation__list {
  display: flex;
}

.navigation__item {
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }
}

.navigation__link {
  color: var(--grey900);
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
}

.active-link {
  color: var(--green700);
}

.navigation__link_bottom {
  color: var(--grey100);
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.2s;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-color: transparent;
    transition: 0.2s;
    top: calc(50% + 30px);
    width: 100%;
    height: 2px;
  }

  &.navigation__link--active,
  &:hover {
    &::after {
      background-color: var(--grey100);
    }
  }
}

.navigation--top {
  margin-right: 180px;
}

.to-right {
  margin-left: auto;
  margin-right: 0;
}

.nickname {
  margin-top: 10px;
  margin-right: 16px;
}

.profile__link {
  color: var(--grey900);
  font-size: 20px;
  text-transform: none;
  text-decoration: none;
}

.free {
  color: var(--green600);
}

.header__search {
  margin-left: auto;
}

.header__user-image {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--grey400);
  object-fit: contain;
}

.header__cart-btn.header__cart-btn {
  margin-left: 100px;
}

.icon-color {
  color: var(--grey100);
}

.profile-link {
  color: inherit;
  text-decoration: none;
}

.menu__name.menu__name {
  margin-bottom: 8px;
}

.menu__profile.menu__profile {
  font-weight: 500;
  font-size: 18px;
  margin-left: 2px;
  padding-top: 2px;
}

.profile__user-image.profile__user-image {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--grey400);
  object-fit: none;
  position: absolute;
  top: 0;
  left: 8px;
}

.menu__logout.menu__logout {
  margin-top: 6px;

  .MuiListItemIcon-root {
    padding-left: 5px;
  }
}

.header__link--publisherPanel.header__link--publisherPanel {
  font-weight: 400;
  border-radius: 0;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--green600);
  text-decoration: none;
  padding: 10px;
  border: 1px solid var(--green600);
  max-height: 45px;
}

.header__mobile-button-container {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .header__top-container {
    padding: 10px 15px;
  }
  .header__bottom-container {
    padding: 23px 15px;
  }
}

@media only screen and (min-width: 1610px) {
  .header__top-container {
    padding: 10px calc((100vw - 1480px) / 2);
  }
  .header__bottom-container {
    padding: 23px calc((100vw - 1480px) / 2);
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .navigation--top {
    margin-right: 50px;
    margin-left: 10px;
  }

  .navigation__item {
    margin-right: 30px;
  }

  .header__start-publishing-container {
    margin-left: auto;
  }
}

@media (max-width: 1024px) {
  .logo__link {
    width: 100px;
    min-width: initial;
    flex-wrap: wrap;

    img {
      width: 100%;
    }
  }

  .logo__unigine {
    margin-right: 0;
    margin-bottom: 2px;
  }

  .navigation--top.navigation--top {
    display: none;
  }
  
  .header__top-container {
    height: 40px;
    padding: 8px 16px;
  }

  .header__user-name {
    display: none;
  }

  .header__user-image {
    width: 24px;
    height: 24px;
  }

  .header__button.header__button {
    padding: 0;
    min-width: 24px;
    font-size: 14px;
    white-space: nowrap;
  }

  .header__bottom-container--pinned {
    top: 40px;
  }

  .header__bottom-container {
    height: 50px;
    padding: 10px 8px;
  }

  .header__mobile-button-container {
    display: flex;
  }

  .header__mobile-button.header__mobile-button {
    color: var(--grey100);
  }

  .navigation-mobile-layout {
    position: fixed;
    display: block;
    background-color: transparent;
    transition: background-color .6s;
    width: 0;
    height: 0;

    &.navigation-mobile-layout--opened {
      background-color: rgba(0,9,19,.8);
      top: 90px;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: calc(100vh - 90px);
    }
  }

  .navigation--mobile {
    position: fixed;
    left: 0;
    top: 90px;
    z-index: 60;
    height: calc(100vh - 90px);
    min-width: 210px;
    text-align: left;
    overflow-y: auto;
    transition: .2s;
    width: 210px;
    transform: translateX(-210px);
    background-color: var(--grey900);
    margin-right: 0;

    &.navigation--opened {
      transform: translateX(0)!important;
    }

    .navigation__list {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 16px;
      margin-top: 12px;
    }

    .navigation__item {
      margin-bottom: 20px;
      margin-right: 0;
    }
    
    .navigation__link_bottom {
      font-size: 14px;
      line-height: 16px;

      &::after {
        top: 20px;
      }
    }
  }

  .header__cart-btn.header__cart-btn {
    margin-left: 15px;
  }

  .navigation-search-container.navigation-search-container.navigation-search-container {
    width: 100%;
    margin-left: 10px;
  }

  .search-wrapper.search-wrapper.search-wrapper {
    width: 100%;
    max-width: initial;
  }

  .menu__link--publisher,
  .menu__link--admin,
  .menu__divider--publisher,
  .menu__divider--admin {
    display: none;
  }

  .header__start-publishing-container {
    display: none;
  }
}