.add-on-packages__item {
  background-color: var(--grey700);
  margin-bottom: 20px;
  padding: 20px 30px 35px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

.add-on-packages__status-container {
  position: absolute;
  top: 0;
  right: 0;

  & .package-status--success {
    display: none;
  }
}

.add-on-packages__file-name {
  display: flex;
  gap: 7px;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--grey100);
  margin-right: auto;
}

.add-on-packages__size {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--grey100);
  align-self: center;
}

.add-on-packages__main {
  display: flex;
}

.add-on-packages__buttons {
  display: flex;
  gap: 25px;
}

.add-on-packages__buttons-wrapper {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .Mui-error {
    text-align: right;
  }
}

.add-on-packages__edit-button.add-on-packages__edit-button {
  width: 165px;
  height: 32px;
  padding: 6px 16px;
  flex-shrink: 0;
  justify-content: space-between;

  span {
    margin-right: auto;
    margin-left: auto;
  }

  &:disabled {
    background-color: var(--greygreen700);
    color: var(--grey200);
    
    path {
      fill: var(--grey200);
    }
  }
}

.add-on-packages__description-container {
  display: flex;
  gap: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.add-on-packages__description-wrapper {
  max-width: calc(100% - 190px);
  flex-grow: 1;
}

.add-on-packages__description {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: var(--grey100);
  flex-grow: 1;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  &.add-on-packages__description--open {
    display: block;
  }
}

.show-more-button {
  background-color: transparent;
  color: var(--green600);
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin-top: 5px;
  padding: 0;
  text-transform: none;
  transition: 0.2s;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.show-more-button--hide {
  display: none;
}

.add-on-packages__description--no-description {
  color: var(--grey600);
}

.add-on-packages__details-container {
  display: flex;
  gap: 28px;
}

.add-on-packages__versions {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: var(--grey100);

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-on-packages__versions-row {
  display: flex;
  align-items: baseline;
  gap: 40px;
}

.add-on-packages__versions-row-name {
  width: 100px;
}

.add-on-packages__action-delete.add-on-packages__action-delete {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--grey400);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  transition: 0.2s;
  padding: 0;

  .MuiTouchRipple-root {
    display: none;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover,
  &:active {
    color: var(--green600);
  }
}

.add-on-packages__actions-container {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
  min-height: 24px;
}

.add-on-packages__action-hide.add-on-packages__action-hide {
  color: var(--grey400);
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0;
  transition: 0.2s;

  &:hover,
  &:focus {
    color: var(--green600);
  }
  
  & .MuiFormControlLabel-label {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
  }

  &.Mui-disabled {
    .MuiFormControlLabel-label {
      color: var(--grey600);
    }
    .checkbox-unigine__icon .checkbox-unigine__fill {
      background-color: var(--grey600);
    }
    .checkbox-unigine__icon .checkbox-unigine__outline {
      border-color: var(--grey600);
    }
  }
}

.add-on-packages__label--replace-package {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 10px;
  color: var(--white);
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  text-transform: none;
  background-color: var(--green600);
  border: none;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;

  width: 165px;
  height: 48px;

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  path {
    fill: var(--white);
  }

  &:hover {
    background-color: var(--green700);
  }

  &:disabled {
    background-color: var(--greygreen700);
    color: var(--grey200);

    path {
      fill: var(--grey200);
    }
  }
}

.add-on-packages__button--download.add-on-packages__button--download {
  width: 165px;
  height: 48px;
  padding: 6px 16px;
  color: var(--grey900);
  background-color: var(--grey300);

  &:hover,
  &:focus {
    background-color: var(--grey200);
  }

  &:disabled {
    color: var(--grey100);
    background-color: var(--grey300);
    path {
      fill: var(--grey100);
    }
  }
  
  & > svg {
    width: 22px;
    height: 23px;
    flex-shrink: 0;
  }

  path {
    fill: var(--grey900);
  }
}

.add-package__label--disabled.add-package__label--disabled {
  background-color: var(--greygreen700);
  color: var(--grey200);
  cursor: default;

  path {
    fill: var(--grey200);
  }
}