.add-on-table-item {
  --extras-size: 2fr;

  position: relative;
  display: grid;
  grid-template-rows: 96px;
  grid-template-columns: 128px 1fr var(--extras-size);
  gap: 20px;
  background-color: var(--grey700);
}

.add-on-table-item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.add-on-table-item__info {
  margin: 14px 0 4px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
}

.add-on-table-item__title {
  position: relative;
  z-index: 1;

  display: -webkit-box;
  max-width: 300px;
  min-height: 44px; // 2 lines

  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: var(--grey100);

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.add-on-table-item__link {
  align-self: stretch;

  color: inherit;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &:hover::before,
  &:focus::before {
    background-color: rgb(255, 255, 255, 0.05);
  }

  &--disabled:hover::before,
  &--disabled:focus::before {
    background-color: transparent;
  }
}

.add-on-table-item__categories {
  margin-top: 2px;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  * {
    word-wrap: break-word;
  }
}

.add-on-table-item__publisher {
  margin-top: 4px;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--grey400);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  &.add-on-table-item__publisher--link {
    cursor: pointer;
    &:hover {
      color: var(--grey300);
    }
  }
}

.add-on-table-item__action {
  position: relative;
  z-index: 1;
}

// Custom price pisplay for ATIs
.table-item-price {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  justify-items: end;
  gap: 4px;

  color: var(--grey100);

  .table-item-price__original {
    grid-row: 1;
    align-self: end;

    font-size: 12px;
    color: var(--grey400);
    text-decoration: line-through;
  }

  .table-item-price__value {
    grid-row: 2;

    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .table-item-price__refunded-notice {
    grid-row: 3;
    align-self: start;
  }

  &.table-item-price--free {
    .table-item-price__value {
      color: var(--green500);
    }
  }

  &.table-item-price--discounted {
    .table-item-price__value {
      color: var(--orange400);
    }
  }

  &.table-item-price--refunded {
    .table-item-price__value {
      text-decoration: line-through;
    }
  }
}
