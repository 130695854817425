.filter-form__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  //margin-top: 18px;
}

.filter-form__title {
  display: flex;
  gap: 4px;

  font-size: 24px;
  font-weight: 500;
  color: var(--grey100);

  &::before {
    content: "";
    width: 24px;
    height: 24px;
    background-image: url("/app/assets/filter.svg");
    background-size: 100%;
  }
}

.filter-form__reset {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--green600);

  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--green700);
  }

  &:active {
    color: var(--green800);
  }
}

.filter-form__search {
  margin-bottom: 20px;
  display: inline-flex;
  position: relative;
  color: var(--grey100);
  margin-top: 12px;
  width: 100%;

  .filter-form__search-input {
    width: 100%;
    padding: 8px 40px 8px 16px;

    font-size: 18px;
    color: inherit;

    background: none;
    border: 1px solid var(--grey500);

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &::placeholder {
      color: var(--grey300);
    }
  }

  .filter-form__search-submit-btn {
    position: absolute;
    height: 100%;
    right: 0;
    color: var(--grey300);
    border: none;
    background: none;
    cursor: pointer;
    :hover {
      color: var(--grey200);
    }
    :active {
      color: var(--grey400);
    }
  }
}

.filter-form__group {
  border-bottom: 1px solid var(--grey600);
  margin-bottom: 20px;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }
}

.filter-form__group-title {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.filter-form__group-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-form__group-icon.filter-form__group-icon {
  fill: white;
  color: white;
  transform:rotate(90deg);
  -moz-transition: all 300ms linear;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.rotate-down.rotate-down { 
  -moz-transform:rotate(0deg);
  -webkit-transform:rotate(0deg);
  transform:rotate(0deg);
}

.filter-form__group-collapse-button.filter-form__group-collapse-button {
  top: -4px;
}
