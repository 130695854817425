.review-reply-form__title {
  font-size: 34px;
  font-weight: 700;
  color: var(--grey100);
}

.review-reply-form {
  display: grid;
  gap: 24px;
}

.review-reply-form__form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.review-reply-form__label {
  display: flex;
  flex-direction: column;
  gap: 12px;

  color: var(--grey100);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;

  text-transform: uppercase;
}

.review-reply-form__submit-btn {
  align-self: flex-end;
  min-width: 140px;
}

.review-reply-form__field.review-reply-form__field {
  .MuiInputBase-root {
    .MuiInputBase-input {
      color: var(--grey300);
    }
  }
}
