.add-ons-managers__item {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--grey700);
  color: var(--grey300);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  padding: 8px 18px;
}

.add-ons-manager__name {
  width: 240px;
  margin-right: 20px;
  word-break: break-all;
}

.add-ons-manager__email {
  width: 380px;
  margin-right: 20px;
  word-break: break-all;
}

.add-ons-manager__button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--grey800);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 17px;
    background-color: var(--green600);
  }

  &::before {
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }
}
