.add-on-section__package-container {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: var(--grey300);
  display: flex;
  max-width: 620px;
  justify-content: space-between;
  flex-grow: 1;
}

.add-on-section--media {
  position: relative;
}

.add-on-section__media-tip {
  position: absolute;
  top: 25px;
  right: 0;
  max-width: 500px;
}

.media__container {
  margin-bottom: 25px;
}

.media__list {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.media__item {
  width: 240px;
}

.media__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media__title {
  color: var(--grey300);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media__list-title {
  color: var(--grey300);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.media__button.media__button {
  width: 240px;
  height: 135px;
  border: 1px solid var(--grey500);
  border-radius: 0;
  position: relative;
  transition: 0.2s;
  outline: 2px solid transparent;

  &.media__button--cover {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 70px;
    height: 3px;
    background-color: var(--grey500);
  }

  &::after {
    transform: rotate(90deg);
  }

  &:hover {
    outline-color: var(--green600);
  }
}

.media__image-container.media__image-container {
  outline: 2px solid transparent;
  width: 240px;
  height: 135px;
  display: flex;
  transition: 0.2s;
  position: relative;

  &:hover,
  &:focus {
    cursor: pointer;
    outline-color: var(--green600);

    & + .media__image-button.media__image-button {
      opacity: 1;
    }
  }
}

.media__action-container {
  position: relative;
}

.media__image-button.media__image-button {
  transition: 0.2s;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  opacity: 0;
  background-color: var(--grey800);
  color: var(--white);
  z-index: 1;

  svg {
    width: 18px;
  }

  &.media__image-button--cover {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &:hover,
  &:focus {
    background-color: var(--grey800);
    opacity: 1;
  }
}

.media__card.media__card {
  width: 240px;
  border-radius: 0;
  background-color: transparent;
  overflow: visible;
}

.media__card--no-image {
  width: 240px;
  height: 135px;
  display: flex;
  background-color: var(--grey500);
  color: var(--grey300);
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.media__item--cover {
  .media__button.media__button,
  .media__image-container.media__image-container,
  .media__card--no-image {
    height: 180px;
  }
}
