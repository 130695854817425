.sort-control {
  display: flex;
  align-items: center;

  font-size: 18px;
  color: var(--grey300);

  .MuiInputLabel-root {
    margin-right: 8px;
    color: var(--grey300);
  }

  .sort-control__select {
    .MuiSelect-select {
      min-height: 16px;
      min-width: 110px;
      line-height: 1.2;
      padding: 5px 12px 4px;
    }
  }

  .sort-control__direction-btn {
    color: var(--grey400);
    padding: 0;
  }

  .sort-control__direction-icon--flipped {
    transform: rotate(180deg);
  }
}
