.form-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 24px;
}

.form-grid__group {
  display: contents;
}

.form-grid__group-label {
  justify-self: start;
  align-self: start;
  margin-top: 6px;
  color: var(--grey300);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;

  text-transform: uppercase;
  &--required::after {
    content: " *";
  }
}

// prevent content from expanding items and overflowing the grid
.form-grid__group-content {
  min-width: 0;
}
