.add-on-base-info {
  flex-basis: 554px;
  display: flex;
  flex-direction: column;
}

.add-on-page-infobox {
  display: flex;
  flex-direction: column;
}

.add-on-base-info__publisher {
  margin-bottom: 16px;
}

.add-on-base-info__title {
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.15px;
  max-width: 500px;
  max-height: 64px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--grey100);
}

.add-on-base-info__stats {
  display: flex;
  gap: 80px;
  // display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  margin-bottom: 45px;
  margin-top: 8px;
}

.add-on-base-info__favorite {
  justify-self: start;
  margin: 0 auto 0 48px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.add-on-base-info__favorite-icon {
  color: var(--green600);
  width: 16px;
  height: 16px;
}

.add-on-base-info__categories {
  margin-left: auto;
}

.purchased-notice {
  display: flex;

  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: var(--greygreen700);
  text-transform: uppercase;
}

.add-on-base-info__purchased-notice {
  margin-top: 16px;
  margin-bottom: 12px;
  align-self: end;
}

.add-on-base-info__price {
  margin-bottom: 12px;
}

.add-on-base-info__obtained-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
}

.add-on-base-info__purchase-btn {
  align-self: end;
}

.add-on-base-info__download-btn {
  align-self: end;

  gap: 8px;
  max-width: 240px;
  width: 208px;
  height: 48px;
  padding: 4px 10px 4px 10px;

  background-color: var(--green600);
  border: none;
  cursor: pointer;
}

.add-on-base-info__download-link {
  align-self: end;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 208px;
  height: 48px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--grey900);
  text-decoration: none;
  background-color: var(--grey300);
  border: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover,
  &:focus {
    background-color: var(--grey200);
  }

  &:active {
    background-color: var(--grey400);
  }
  &.disabled {
    color: var(--grey100);
    background-color: var(--grey300);
    pointer-events: none;
    cursor: default;
  }
}

.add-on-base-info__download-helper {
  font-size: 18px;
  margin-top: 20px;
  color: var(--grey100);
}

.add-on-datalist {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: auto;

  &.modal {
    margin-top: 0px;
  }
}

.add-on-datalist__link {
  color: var(--green600);
  text-decoration: underline;
}

.comma-color {
  color: var(--green600);
}

.add-on-base-info__mobile-media {
  display: none;
}

@media (max-width: 1024px) {
  .add-on-base-info__title {
    font-size: 20px;
    line-height: 24px;
    margin-top: 10px;
  }

  .add-on-base-info__purchase-btn.add-on-base-info__purchase-btn {
    width: 100%;
    max-width: initial;
  }

  .add-on-datalist {
    margin-top: 16px;
  }

  .add-on-base-info__mobile-media {
    display: block;
    margin-bottom: 10px;

    .media-viewer__thumbs,
    .media-viewer__thumbs-strip {
      height: 54px;
    }

    .media-viewer__thumb {
      width: 96px;
    }
  }

  // .add-on-base-info__obtained-wrapper {
  //   display: none;
  // }

  .add-on-base-info__stats {
    flex-direction: column;
    gap: 15px;
  }
}
