.add-on-section {
  padding-top: 25px;
  padding-bottom: 20px;
}

.add-on-section__block {
  display: flex;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: var(--grey300);

  &:last-child {
    margin-bottom: 0;
  }
}

.add-on-section__block-name {
  text-transform: uppercase;
  width: 260px;
  flex-shrink: 0;
}

.add-on-section__category-link {
  text-decoration: none;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: var(--grey300);
}

.add-on-section__block-content {
  word-break: break-word;

  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul, ol {
    list-style: initial;
    margin-left: 2em;
  }

  ol {
    list-style: decimal;
  }

  li {
    margin-bottom: 10px;
  }
}

.add-on-section__block-content--text {
  a {
    color: var(--green600);
  }

  strong {
    font-weight: 700;
  }
}

.add-on-section__block-content--tag {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.add-on-section__tag {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid var(--grey600);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 12px;
}