.start-publishing__text-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0px 14px 9px;
  border: 1px solid var(--grey300);
  margin-bottom: 35px;

  h1 {
    font-weight: 300;
    font-size: 36px;
    line-height: 140%;
    margin-bottom: 10px;
  }
  
  h3 {
    font-weight: 300;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 10px;
  }

  b, strong {
    font-weight: 500;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
  
  ol, ul {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ol > li {
    margin-bottom: 20px;
    margin-left: 30px;

    li {
      margin-bottom: 10px;
      margin-left: 20px;
    }
  }

  li {
    padding-left: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  a {
    color: var(--green600);
  }
}

.start-publishing__text-container {
  font-size: 18px;
  line-height: 24px;
  color: var(--grey300);
  font-weight: 300;
  overflow-y: scroll;
  max-height: 500px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--grey900);
  }
  
  &::-webkit-scrollbar-thumb {
    background: var(--green600);
  }

  p {
    margin-bottom: 25px;
  }
}

.start-publishing {
  max-width: 768px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .orders__heading {
    margin-bottom: 40px;
    white-space: nowrap;
    margin-bottom: 20px;
  }
}

.start-publishing__buttons-container {
  display: flex;
  gap: 16px;
  align-self: flex-end;
}

.start-publishing__button-decline {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  width: 180px;
  height: 48px;
  background-color: var(--grey300);
  justify-content: center;
  color: var(--grey900);
  text-transform: uppercase;
}

.start-publishing__button-accept.start-publishing__button-accept {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  width: 180px;
  height: 48px;
  background-color: var(--green600);
  justify-content: center;
  color: var(--white);
  border-radius: 0;

  &:hover,
  &:focus {
    background-color: var(--green600);
    color: var(--white);
  }
}

.start-publishing__action-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: baseline;

  .checkbox-group__item {
    margin-left: 9px;
    align-items: flex-start;
  }

  .checkbox-unigine {
    margin-right: 10px;
  }

  .MuiFormControlLabel-label {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: var(--grey300);
  }
}