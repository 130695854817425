.order-page {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  color: var(--grey100);
}

.order-page__spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.order-page__error-message {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  color: var(--grey300);
}

.order-page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.order-page__title {
  font-weight: 300;
  font-size: 36px;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  color: var(--grey200);
}

.order-page__status {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 20px;
  text-transform: uppercase;

  &::before {
    content: "";
    width: 30px;
    height: 30px;
    background-size: contain;
    background-position: 0 50%;
    background-repeat: no-repeat;
  }

  &--completed::before {
    background-image: url("/app/assets/order-status-completed.svg");
  }
  &--canceled::before {
    background-image: url("/app/assets/order-status-cancelled.svg");
  }
  &--refunded::before {
    background-image: url("/app/assets/order-status-refunded.svg");
  }
  &--not_paid::before {
    background-image: url("/app/assets/order-status-unpaid.svg");
  }
  &--pending_payment::before {
    background-image: url("/app/assets/order-status-unpaid.svg");
  }
}

.order-page__back-link {
  color: var(--green500);
  text-decoration: none;

  &:hover {
    color: var(--green400);
  }

  &:active {
    color: var(--green600);
  }
}

.order-page__info {
  margin-bottom: 30px;
}

.order-info {
  display: flex;
  justify-content: space-between;
  min-height: 92px;
  padding: 16px;

  background-color: var(--grey700);
}

.order-info__general {
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-size: 20px;
}

.order-info__number {
  font-size: 16px;
}

.order-info__price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.order-info__discount {
  color: var(--orange400);
}

.order-info__date {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

.order-info__number {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

.order-info__total {
  margin-top: auto;
  font-size: 20px;
  font-weight: 500;

  &--free {
    text-transform: uppercase;
    color: var(--green500);
  }
}

.order-page__add-on-list {
  border-bottom: 1px solid var(--grey600);
}

.order-page__add-on-list-item {
  margin-bottom: 20px;
  gap: 16px;
  width: 100%;
}

.ordered-add-on {
  display: flex;
  background-color: var(--grey700);
}

.ordered-add-on__thumb-container {
  position: relative;
  min-height: 96px;
  min-width: 128px;
  margin-inline-end: 20px;
}

.ordered-add-on__thumb {
  position: absolute;
  width: 100%;
  height: 100%;

  object-fit: cover;
  cursor: pointer;
}

.ordered-add-on__info {
  display: flex;
  flex-direction: column;

  padding: 12px 0;
  margin-inline-end: auto;
}

.ordered-add-on__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: auto;
  cursor: pointer;
  color: var(--grey100);
}

.ordered-add-on__categories {
  margin-bottom: 4px;

  font-size: 12px;
  text-transform: uppercase;
  color: var(--green600);
  line-height: 14px;
}

.ordered-add-on__category-link {
  text-decoration: none;
  color: var(--green600);

  &:hover {
    color: var(--green400);
  }

  &:active {
    color: var(--green500);
  }
}

.ordered-add-on__publisher {
  font-size: 12px;
  color: var(--grey400);
}

.ordered-add-on__price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 1px;
}

.ordered-add-on__price {
  align-self: center;
  margin-inline-end: 54px;
  font-weight: 500;

  font-size: 20px;
  text-transform: uppercase;
  &--free {
    color: var(--green500);
  }
  &--refunded {
    text-decoration: line-through;
    margin-inline-end: 28px;
  }
  &--sale {
    color: var(--orange400);
  }
}

.ordered-add-on__base-price {
  color: var(--grey400);
  text-decoration: line-through;
  font-weight: 500;
  font-size: 14px;
  margin-inline-end: 54px;
  text-align: right;
}

.ordered-add-on__refunded-info {
  color: var(--grey100);
  margin-inline-end: 28px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
}

.ordered-add-on__browser-link {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 240px;
  padding: 12px;

  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  color: white;
  margin-right: 24px;

  background-color: var(--green600);
  border: none;
  cursor: pointer;

  &:hover {
    background-color: var(--green700);
  }

  &:active {
    background-color: var(--green800);
  }

  &:disabled {
    color: var(--grey100);
    background-color: var(--grey300);
    cursor: initial;
  }
}

.order-page__action-container {
  display: flex;
  margin-top: 32px;
  width: 100%;
  justify-content: flex-end;
}

.order-page__button {
  width: 240px;
  height: 60px;

  &.order-page__button--refresh {
    background-color: var(--grey300);
    color: var(--grey900);

    &:hover,
    &:focus {
      background-color: var(--grey400);
    }

    &:active {
      background-color: var(--grey300);
    }

    &:disabled {
      background-color: var(--grey400);
    }
  }
}

.order-page__pay-order {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.order-button__wrapper {
  display: flex;
  justify-content: flex-end;
}

.order-button--refund {
  display: flex;
  align-items: center;
  background-color: var(--grey300);
  color: var(--grey900);
  padding: 10px 60px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-decoration: none;
  margin-top: 26px;
  gap: 8px;

  &:hover {
    background-color: var(--grey200);
  }

  &.order-button--disabled {
    background-color: var(--grey300) !important;
    color: var(--grey100) !important;
    cursor: default;

    svg path {
      fill: var(--grey100);
    }
  }
}
