.publisher-table {
  //closed sidebar
  display: flex;
  flex-direction: column;
  color: var(--grey100);
  width: 100vw;
  transition: 350ms;
  transition-property: all;
  transition: padding-top 0s;
  position: relative;
}

.publisher-panel__wrapper {
  display: flex;
  min-height: calc(100vh - 60px - 270px);
}

.main--publisher-panel {
  padding-top: 20px;

  .add-on-table__column--side {
    width: 300px;
  }
}

.add-add-on-button {
  align-self: end;

  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  padding: 14px 32px 14px 32px;

  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  color: white;

  background-color: var(--green600);
  border: none;
  cursor: pointer;

  &:hover {
    background-color: var(--green700);
  }

  &:active {
    background-color: var(--green800);
  }

  &:disabled {
    color: var(--grey100);
    background-color: var(--grey300);
    cursor: initial;
  }
}

.publisher-panel__wrapper--no-header {
  margin-top: 45px;
}

.main--publisher-add-on {
  padding-top: 0;
  transition: padding-top 0s;
}

.main--fixed-sidebar {
  width: calc(100vw - 300px);
}

@media (max-width: 1609px) {
  .main--publisher-panel {
    margin-left: 75px;
    margin-right: 15px;

    &.main--fixed-sidebar {
      margin-left: 0;
      margin-right: 0;
      padding-right: 15px;
      padding-left: 40px;
    }

    .add-on-table__title {
      font-weight: 300;
      font-size: 36px;
      line-height: 42px;
      text-transform: uppercase;
    }
  }
}

@media (min-width: 1610px) {
  .main--publisher-panel {
    padding-right: calc((100vw - 1480px) / 2);
    padding-left: calc((100vw - 1480px) / 2);

    &.main--fixed-sidebar {
      padding-left: 80px;
    }
  }
}

@media (min-width: 1760px) {
  .main--publisher-panel.main--fixed-sidebar {
    padding-left: 140px;
  }
}

@media (min-width: 2300px) {
  .main--publisher-panel.main--fixed-sidebar {
    padding-left: calc((100vw - 1480px) / 2 - 300px);
  }
}
