.add-on-version-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: var(--grey100);
  text-transform: uppercase;
  background-color: var(--draftBg);
  border-left: 8px solid var(--draft);

  &.add-on-version-status--draft {
    background-color: var(--draftBg);
    border-left: 8px solid var(--draft);
  }

  &.add-on-version-status--moderation {
    background-color: var(--moderationBg);
    border-left: 8px solid var(--moderation);
  }

  &.add-on-version-status--published {
    background-color: var(--publishedBg);
    border-left: 8px solid var(--published);
  }

  &.add-on-version-status--error {
    background-color: var(--errorBg);
    border-left: 8px solid var(--error);
  }

  &.add-on-version-status--revision {
    background-color: var(--revisionBg);
    border-left: 8px solid var(--revision);
  }

  &.add-on-version-status--archived {
    background-color: var(--archivedBG);
    border-left: 8px solid var(--archived);
  }

  &.add-on-version-status--banned {
    background-color: var(--bannedBg);
    border-left: 8px solid var(--banned);
  }
}