.add-on-star__rating-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-on-star__wrapper {
  svg {
    width: 30px;
    height: 30px;
  }
}

.add-on-star__rating-number {
  color: var(--grey100);
  font-weight: 700;
  font-size: 28px;
}

.add-on-widgets {
  margin-top: 16px;

  display: flex;
  gap: 16px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.add-on-widgets__widget {
  padding: 14px 32px;

  font-size: 18px;
  font-weight: 400;

  background-color: var(--grey700);

  @media (max-width: 1024px) {
    padding: 14px 12px;
  }

  &--reviews-link {
    flex-basis: 200px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    color: inherit;
    text-decoration: none;

    &:hover {
      background-color: var(--grey800);
    }

    @media (max-width: 1024px) {
      flex-basis: 160px;
    }
  }

  &--reviews-link-inactive {
    &:hover {
      background-color: var(--grey700);
    }
  }

  &--no-reviews {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}
