.unigine-form-field {
  .MuiInputBase-root {
    color: var(--grey500);

    svg {
      fill: var(--grey500);
    }

    .MuiInputBase-input {
      color: var(--grey500);
    }

    &.MuiInputBase-root:hover {
      color: var(--grey400);
    }

    &.MuiInputBase-root.Mui-error {
      color: var(--errorBg);
    }

    &.MuiInputBase-root.Mui-error:hover {
      color: var(--errorBg);
    }

    &.MuiInputBase-root.Mui-disabled,
    &.MuiInputBase-root.Mui-disabled:hover {
      color: var(--grey600);
      .MuiInputBase-input {
        color: inherit;
        -webkit-text-fill-color: currentColor;
      }
    }

    .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
      border-color: currentColor;
      border-radius: 0;
    }
    &.Mui-focused:not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
        border-color: var(--green600);
      }
    }
  }

  .MuiInputLabel-root {
    color: var(--grey500);

    &.MuiInputLabel-root:hover {
      color: var(--grey400);
    }

    &.MuiInputLabel-root.Mui-error {
      color: #a93300;
    }

    &.MuiInputLabel-root.Mui-disabled {
      color: var(--grey600);
    }

    &.MuiInputLabel-root.Mui-focused:not(.Mui-error) {
      color: var(--green600);
    }
  }

  input::-webkit-calendar-picker-indicator {
    filter: invert(0.6);
  }
}

.add-on-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-on-form__select {
  &.MuiInputBase-root {
    border: 1px solid var(--grey500);
    border-radius: 0;

    &.Mui-focused {
      .MuiInputBase-input {
        outline: none;
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }

  .MuiInputBase-input {
    min-width: 180px;
    padding: 8px;
    color: var(--grey400);
  }

  .MuiSelect-icon {
    color: var(--grey400);
  }
}

.add-on-form__price-section-wrapper {
  display: flex;
  gap: 24px;
}

.add-on-form__price-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  margin-bottom: -9px;
}

.add-on-form__is-free.add-on-form__is-free {
  margin-left: -4px;
  margin-top: 8px;

  .MuiFormControlLabel-label {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: var(--grey400);
  }

  .MuiCheckbox-root {
    margin-right: 10px;
  }

  &.Mui-disabled {
    .MuiFormControlLabel-label {
      color: var(--grey500);
    }
  }
}

.add-on-form__discounts {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.add-on-form__discount-row {
  display: flex;
  gap: 8px;
  position: relative;

  .add-on-form__discount-date-clear {
    position: absolute;
    color: var(--grey500);
    left: 600px;

    &:hover {
      color: var(--grey400);
    }

    &.add-on-form__discount-date-clear--hide {
      display: none;
    }
  }

  .unigine-form-field {
    max-width: 231px;
  }

  .MuiFormHelperText-root {
    display: none;

    &.Mui-error {
      display: block;
    }
  }
}

.add-on-form__discount-add-btn {
  align-self: flex-start;
  width: 160px;
  height: 32px;
}

.add-on-form__discount-remove-btn.add-on-form__discount-remove-btn {
  color: var(--grey500);
  align-self: baseline;
  &:hover {
    color: var(--grey400);
  }
  &:active {
    color: var(--grey600);
  }
}

.add-on-form__submit-btn.add-on-form__submit-btn {
  align-self: flex-end;
  min-width: 240px;

  font-size: 20px;
}

.add-add-on__title,
.add-add-on__title-link {
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: var(--grey200);
  text-decoration: none;
}

.add-add-on__title {
  margin-bottom: 20px;
}
