.published-item {
  display: flex;

  .add-on-table-item {
    --extras-size: 1fr;
  }
}

.published-item__addon {
  flex-grow: 1;
}

.published-item__info {
  min-width: 180px;
  height: calc(100% - 10px);
  margin: 8px 16px 8px 0;
  display: grid;
  place-content: center start;
  gap: 4px;
  padding-left: 16px;

  border-left: 1px solid var(--grey600);
}

.published-item__last-version {
  text-overflow: ellipsis;
  overflow: hidden;
}

.published-item__status {
  flex-shrink: 0;
  margin-left: auto;
  margin-right: 0;
  width: 200px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--grey100);
  background-color: var(--draftBg);
  border-right: 8px solid var(--draft);

  &.published-item__status--draft {
    background-color: var(--draftBg);
    border-right-color: var(--draft);
  }

  &.published-item__status--moderation {
    background-color: var(--moderationBg);
    border-right-color: var(--moderation);
  }

  &.published-item__status--published {
    background-color: var(--publishedBg);
    border-right-color: var(--published);
  }

  &.published-item__status--banned {
    background-color: var(--bannedBg);
    border-right-color: var(--banned);
  }

  &.published-item__status--revision {
    background-color: var(--revisionBg);
    border-right-color: var(--revision);
  }

  &.published-item__status--archived {
    background-color: var(--archivedBg);
    border-right-color: var(--archived);
  }
}

@media (max-width: 1600px) {
  .main--fixed-sidebar {
    .published-item__status {
      width: 130px;
      font-size: 16px;
    }
  }
}

@media (max-width: 1024px) {
  .published-item .add-on-table-item {
    --extras-size: 0fr;
  }

  .published-item__info {
    display: none;
  }
}
