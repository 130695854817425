.dialog-mobile {
  background-color: var(--grey900);
}

.dialog-mobile__content {
  background-color: var(--grey900);
  color: var(--grey100);

  .filter-form__header {
    margin-bottom: 0;
    justify-content: flex-end;
  }

  .filter-form__title {
    display: none;
  }
}

.dialog-mobile__title.dialog-mobile__title {
  background-color: var(--grey900);
  color: var(--grey100);
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.dialog-mobile__actions.dialog-mobile__actions {
  background-color: var(--grey900);
  color: var(--grey100);
  display: flex;
  justify-content: center;
  align-items: center;

  .unigine-button {
    height: 36px;
    width: 180px;
  }
}