.addon-order-item__info {
  height: 100%;
  padding: 8px 24px 8px 0;
  display: flex;
  justify-content: space-between;
}

.addon-order-item__purchase-details {
  display: grid;
  place-content: center start;
  gap: 4px;
  padding-left: 16px;

  border-left: 1px solid var(--grey600);
}

@media (max-width: 1024px) {
  .add-on-table-item {
    --extras-size: 0fr;
  }

  .addon-order-item__info {
    display: none;
  }
}
