.add-on-card_wrapper {
  display: flex;
  width: 240px;

  &.purchased :hover {
    background-color: var(--greygreen700);
  }

  &:hover .add-on-card__image-container {
    background-color: var(--grey900);
    transition: 0.3s;
  }

  &:hover .quick-look-rectangle {
    display: block;
    background-color: transparent;
    transition: 0.3s;
  }

  &:hover .add-on-card__image {
    opacity: 0.4;
  }
}

.add-on-card {
  color: var(--black);
  background-color: var(--grey700);

  // specificity boost
  &.add-on-card {
    background-color: var(--grey700);
    border-radius: 0;
    transition: none;
  }

  &.add-on-card--purchased {
    background-color: var(--greygreen800);
  }
}

.add-on-card__autor {
  font-size: 12px;
  color: var(--grey400);
  font-weight: 400;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.add-on-card__text-container {
  height: 140px;
}

.add-on-card__title {
  margin-bottom: 6px;
  margin-top: 6px;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  text-overflow: ellipsis;
  color: var(--grey100);
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 1px;
}

.add-on-card__title-link {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  color: var(--grey100);
  text-decoration: none;
}

.add-on-card__category {
  font-size: 12px;
  font-weight: 400;
  color: var(--green600);
  text-transform: uppercase;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.add-on-card__reviews {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.add-on-card-like {
  margin-left: auto;
  width: 20px;
  height: 20px;
}

.add-on-card__price {
  color: var(--grey100);
  display: flex;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  margin-bottom: 6px;
  margin-left: auto;
  margin-top: auto;
  justify-content: flex-end;
}

.add-on-card__sale-price {
  color: var(--orange400);
  margin-inline-end: 5px;
}

.add-on-card__base-price {
  color: var(--grey400);
  font-size: 16px;
  text-decoration: line-through;
  margin-top: 2px;
}

.add-on-card__price-buy-button.add-on-card__price-buy-button {
  color: var(--grey100);
  font-size: 20px;
  font-weight: 500;
  margin-left: 12px;

  &.add-on-card__price-buy-button--in-cart {
    color: var(--green600);
  }

  &.add-on-card__price-buy-button--in-order {
    color: var(--greygreen700);
  }
}

.add-on-card__buy-button-spinner.add-on-card__buy-button-spinner {
  color: var(--grey100);
}

.add-on-card__notice {
  text-align: end;
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;

  &--purchased {
    color: var(--white);
  }

  &--unavailable {
    color: var(--grey500);
  }
}

.free-color {
  color: var(--green600);
}

.stars-add-on-card {
  display: flex;
  align-items: center;
}

.add-on-card__image-container {
  display: flex;
}

.add-on-card__image {
  height: 180px;
  object-fit: cover;

  opacity: 1;
  transition: 0.3s;
}

.quick-look-rectangle {
  z-index: 4;
  position: absolute;
  bottom: 66px;
  left: 45px;
  width: 150px;
  height: 48px;
  border: 2px solid var(--grey400);
  color: var(--grey400);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  display: none;
  background-color: transparent;
}

.add-on-card_wrapper :hover {
  background-color: var(--grey600);
  transition: 0.2s;
}
