.MuiDialog-paper.MuiDialog-paper,
.dialog {
  border-radius: 0;
}

.dialog__close-button.dialog__close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  color: var(--grey400);

  &:focus,
  &:hover,
  &:active {
    color: var(--white);
  }
}

.dialog__content.dialog__content {
  padding: 32px;
  background-color: var(--grey700);
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 800px;

  &--loading {
    display: flex;
    justify-content: center;
  }

  scrollbar-color: var(--green500);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: var(--green500);
  }
}

.add-on__dialog {
  color: var(--grey100);
  margin-bottom: 0;
  gap: initial;

  .add-on__media {
    flex-basis: initial;
    flex-grow: 1;
    flex-shrink: initial;
    margin-right: 25px;
  }

  .add-on__base-info {
    display: flex;
    flex-basis: initial;
    flex-grow: initial;
    flex-shrink: 0;
    width: 400px;

    .add-on-base-info {
      width: 100%;
    }
  }

  .add-on-base-info__category-link {
    margin-bottom: 15px;
    font-size: 16px;
  }

  .add-on-base-info__stats {
    margin-bottom: 15px;
  }

  .add-on-base-info__purchased-notice--dialog.add-on-base-info__purchased-notice--dialog {
    //margin: 0 20px 0 0;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 0px;
  }

  .add-on-datalist {
    margin-bottom: 16px;
  }

  .add-on-base-info__buy-btn,
  .add-on-base-info__download-btn {
    margin-bottom: 0;

    min-width: 180px;
    max-width: 180px;
    max-height: 45px;
  }

  .hidden-purchase-button {
    margin-bottom: 22px;
  }

  @media (max-width: 1024px) {
    .add-on__base-info {
      flex-grow: 1;
    }
  }
}

.dialog__loading {
  color: var(--grey400);
}

.dialog__button {
  align-self: end;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 45px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--grey900);
  text-decoration: none;
  background-color: var(--grey300);
  border: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover,
  &:focus {
    background-color: var(--grey200);
  }

  &:active {
    background-color: var(--grey400);
  }
}

.dialog__bottom-container {
  display: flex;
  margin-top: auto;
  align-self: flex-end;
  gap: 24px;
}

.dialog__actions {
  margin-left: auto;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dialog__price {
  align-self: flex-end;
}

// .dialog__title {
//   margin-bottom: 185px;
// }

// .dialog__publisher-link {
//   margin-bottom: 10px;
// }

.dialog__category {
  margin-bottom: 12px;
}

.dialog__author-container {
  margin-bottom: 10px;
}

.dialog__no-add-on {
  color: var(--grey100);
}

.dialog__rating {
  margin-bottom: 14px;
}

.add-on__header-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.add-on-base-info-description {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  min-height: 170px;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;

  a {
    color: var(--green600);
  }

  margin-bottom: 60px;
}
