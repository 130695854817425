.category-tags {
  color: var(--green600);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.category-tags__link {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: var(--green400);
  }
}
