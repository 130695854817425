.media-viewer__slide {
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 0 58.333% 0;
  margin-bottom: 16px;

  background: repeating-linear-gradient(
    45deg,
    #0000,
    #0000 6px,
    #a2b4c608 6px,
    #a2b4c608 12px
  );
}

.media-viewer__slide-image,
.media-viewer__video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.media-viewer__video {
  border: none;
}

.media-viewer__controls {
  display: flex;
  gap: 8px;
  max-width: 100%;
}

.media-viewer__prev,
.media-viewer__next {
  font-weight: 700;
  font-size: 24px;
  color: white;

  border: none;
  background: none;
  cursor: pointer;
  stroke: var(--grey400);
  display: flex;
  align-items: center;

  &:disabled {
    stroke: var(--grey600);
    cursor: initial;
  }

  &:hover:not(:disabled) {
    stroke: var(--grey200);
  }
}

.media-viewer__next svg {
  transform: rotate(180deg);
}

.media-viewer__thumbs {
  position: relative;

  height: 81px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.media-viewer__thumbs-strip {
  position: absolute;
  display: flex;
  gap: 8px;
  height: 81px;

  transition: left 0.3s ease;
}

.media-viewer__thumb {
  position: relative;
  width: 144px;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    background-color: var(--grey900);
    opacity: 0.4;
  }

  &:hover::before {
    opacity: 0.2;
  }

  &--active::before,
  &--active:hover::before {
    background: none;
    opacity: 1;
    box-shadow: 0 0 0 1px inset var(--green600);
  }

  &--video::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;

    border-left: 30px solid rgba($color: white, $alpha: 0.5);
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    transform: translate(-15px, -20px);
    filter: drop-shadow(0 0 5px black);
  }
}

.media-viewer__thumb-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
