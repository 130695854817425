.stars__rating-container {
    display: flex;
    align-items: center;
    gap: 4px;
}
.stars__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.stars {
    color: var(--green600);
    width: 18px;
    height: 18px;
}

.number-reviews {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--grey100);
}