.rating__wrapper {
  display: flex;
  align-items: center;
}
.star__button {
  display: flex;
  background-color: transparent;
  width: 20px;
  height: 20px;
  border: none;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  padding-right: 5px;
  box-sizing: content-box;

  svg {
    width: 100%;
    height: 100%;
    stroke: var(--green500);
  }
}

.star__button--filled {
  svg {
    fill: var(--green500);;
    color: var(--green500);
  }
}