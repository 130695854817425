.rich-text-editor {
  align-self: stretch;
  max-width: 768px;
  gap: 8px;

  --focus-color: var(--green600);

  &--error {
    --focus-color: var(--orange700);
  }

  .rich-text-editor__helper-text {
    font-size: 14px;
    color: var(--focus-color);
    display: block;
    margin-top: 10px;
  }

  --ck-focus-ring: 1px solid var(--focus-color);

  max-width: unset;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 300px;

  > .ck-editor,
  > .ck-editor > .ck-editor__main,
  > .ck-editor > .ck-editor__main > .ck-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
