.settings__title-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.settings__title {
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: var(--grey200);
}

.settings__info-block {
  margin-top: -12px;
}

.settings__info-row {
  display: flex;
  margin-bottom: 30px;
}

.settings__info-title {
  width: 160px;
  flex-shrink: 0;
  margin-right: 40px;
}

.settings__info-info {
  max-width: 800px;
  flex-grow: 1;
  text-transform: none;
}

.settings__info-description {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;

  &--open {
    display: block;
  }
}

.settings__button-expand.settings__button-expand {
  color: var(--green600);
  text-transform: none;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 300;
  padding: 0;
  text-decoration: underline;
  line-height: 24px;
  background-color: transparent;
  transition: 0.2s;
}

.settings__contacts-wrapper {
  text-transform: none;
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  column-gap: 30px;
  width: 70%;
}

.settings__contacts {
  display: flex;
  align-items: center;
}

.settings__contacts-icon {
  margin-right: 10px;
}

.settings__avatar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings__change-avatar-button.settings__change-avatar-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: 60px;
  padding: 10px 20px 10px 20px;
  border-radius: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  color: white;
  margin-left: 30px;
  background-color: var(--green600);
  border: none;
  cursor: pointer;

  &:hover {
    background-color: var(--green700);
  }

  &:active {
    background-color: var(--green800);
  }

  &:disabled {
    color: var(--grey100);
    background-color: var(--grey300);
    cursor: initial;
  }
}

.to-right.to-right {
  margin-left: auto;
  margin-right: 0;
}

.settings__buttons-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  text-align: center;
}

.settings__edit-button.settings__edit-button {
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  padding: 8px 62px 8px 62px;

  text-decoration: none;
  color: white;

  background-color: var(--green600);
  border: none;
  cursor: pointer;

  &:hover {
    background-color: var(--green700);
  }

  &:active {
    background-color: var(--green800);
  }

  &:disabled {
    color: var(--grey100);
    background-color: var(--grey300);
    cursor: initial;
  }
}

.settings__view-profile-button.settings__view-profile-button {
  min-height: 32px;
  padding: 8px 62px 8px 62px;

  text-decoration: none;
  background-color: var(--grey200);
  color: var(--grey900);

  &:hover,
  &:focus {
    background-color: var(--grey300);
  }

  &:active {
    background-color: var(--grey200);
  }
}
