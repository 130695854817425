.dashboard__item {
  background-color: var(--grey700);
  padding: 30px 32px 24px;
  width: 375px;
  margin-bottom: 12px;
}

.dashboard__item-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: var(--grey200);
}

.dashboard__item-title-container {
  display: flex;
  margin-bottom: 35px;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard__item-value-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.dashboard__item-value {
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;

  &.dashboard__item-value--orange {
    color: var(--orange400);
  }

  &.dashboard__item-value--green {
    color: var(--green600);
  }

  &.dashboard__item-value--grey {
    color: var(--grey400);
  }
}

.dashboard__item-dates {
  position: absolute;
  bottom: -9px;
  color: var(--grey400);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.dashboard__item-percent {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 8px;
    height: 21px;
    display: block;
    margin-right: 5px;
  }

  &.dashboard__item-percent--up {
    color: var(--orange400);

    &::before {
      background-image: url('/app/assets/icon_arrow_up.svg');
    }
  }
  
  &.dashboard__item-percent--down {
    color: var(--grey300);

    &::before {
      background-image: url('/app/assets/icon_arrow_down.svg');
    }
  }
}

.dashboard__item-value--skeleton.dashboard__item-value--skeleton {
  background-color: var(--grey900);
}

.dashboard__button {
  width: 122px;
}