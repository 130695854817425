.add-on-reviews-page {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.add-on-reviews-page__main {
  margin-bottom: 20px;

  display: grid;
  grid-template-columns: 960px 1fr;
  grid-template-areas: "reviews stats";

  gap: 64px 20px;

  @media (max-width: 1024px) {
    grid-template-columns: 100% 1fr;
    gap: 0;
  }
}

.add-on-reviews-page__section-title {
  margin-bottom: 32px;

  font-weight: 700;
  font-size: 28px;
  line-height: 32px;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
}

.add-on-reviews-page__add-on-info {
  margin-bottom: 20px;
}

.add-on-reviews-page__infobox-container {
  position: relative;
  padding: 64px 15px 20px;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 1610px) {
    padding: 64px calc((100vw - 1480px) / 2) 20px;
  }

  @media (max-width: 1024px) {
    padding-top: 30px;
  }
}

.add-on-reviews-page__back-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.add-on-reviews-page__back-link:hover,
.add-on-reviews-page__back-link:focus {
  background-color: var(--grey800);
}

.add-on-reviews-page__infobox {
  flex-grow: 1;
  position: relative;
  pointer-events: none;

  .publisher-link,
  .category-tags,
  .unigine-button {
    pointer-events: initial;
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    gap: 12px;
  }
}

.add-on-reviews-page__reviews {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.reviews__loading {
  align-self: center;
}

.add-on-reviews__user-review {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--grey300);
  margin-bottom: 35px;
}

.add-on-reviews__actions-container {
  display: flex;
  width: 100%;
  position: absolute;
  justify-content: flex-end;
}

.add-on-reviews__log-in-tip {
  max-width: 280px;
  padding: 14px;
}

.reviews__loading .add-on-reviews__review-btn {
  min-width: 180px;
  padding: 14px 16px;
  font-size: 20px;
}

.add-on-reviews__update-btn {
  min-width: 148px;
  padding: 6px 16px;
  font-size: 20px;
}

@media (max-width: 1024px) {
  .add-on-reviews-page__section-title {
    margin-bottom: 8px;
  }

  .add-on-reviews__actions-container {
    margin-bottom: 24px;
    position: initial;
    justify-content: flex-start;
  }
}
