.add-on-navigation {
  border-bottom: 1px solid var(--grey300);
}

.add-on-navigation__list {
  display: flex;
  gap: 40px;
  padding-left: 56px;
  padding-right: 56px;
}

.add-on-navigation__link {
  font-size: 18px;
  color: var(--grey500);
  line-height: 21px;
  text-decoration: none;
  transition: 0.2s;
  position: relative;
  display: flex;
  padding-bottom: 12px;

  &::after {
    content: '';
    background-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    transition: 0.2s;
  }

  &--active {
    color: var(--white);

    &::after {
      background-color: var(--green600);
    }
  }
}