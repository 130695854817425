.addon-notification__wrapper {
  padding: 60px 40px 40px 40px;
  background-color: var(--grey700);
  color: var(--grey300);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
}

.addon-notification__content {
  display: flex;
  gap: 24px;

  a {
    color: var(--green500);
  }

  .addon-notification__icon-info {
    width: 40px;
    height: 40px;
    filter: invert(92%) sepia(19%) saturate(48%) hue-rotate(164deg) brightness(96%) contrast(88%);
  }
}

.addon-notification__buttons {
  display: flex;
  margin-top: 16px;
  gap: 24px;

  .addon-notification__checkbox {
    display: flex;
    color: var(--grey400);
    font-size: 16px;
    gap: 8px;
  }

  .addon-notification__close-button {
    width: 180px;
    height: 48px;
  }
}