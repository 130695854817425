:root {
  --black: #000000;
  --white: #ffffff;

  --grey900: #10151c;
  --grey800: #181e26;
  --grey700: #2a323b;
  --grey600: #545b63;
  --grey500: #737a81;
  --grey400: #a0a6ac;
  --grey300: #c0c6cb;
  --grey200: #dbe0e4;
  --grey180: #b9b9b9;
  --grey150: #e7e7e7;
  --grey100: #eff3f7;

  --crossposter: #9497A2;
  --windowsposter: #00A8EC;
  --linuxposter: #FFC449;

  --green800: #497921;
  --green700: #629438;
  --green600: #7cad53;
  --green500: #8fb96d;
  --green400: #a0c383;
  --green100: #dfedd4;

  --greygreen800: #536358;
  --greygreen700: #718967;
  --greygreen800: #536358;

  --black000: #000913;

  --orange400: #ff9e41;
  --orange700: #D43E00;
  --orange800: #A93300;

  --moderation: #0392CD;
  --moderationBg: #105473;

  --draft: #FFEFCC;
  --draftBg: #878275;

  --revision: var(--orange400);
  --revisionBg: #896341;

  --published: var(--green700);
  --publishedBg: #3a532c;

  --error: #D43E00;
  --errorBg: #A93300;

  --archived: var(--grey500);
  --archivedBg: #4B525A;

  --banned: var(--orange700);
  --bannedBg: #7E3821;
}

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  margin: 0 15px;
  flex-grow: 1;
}

.main--filters {
  padding-top: 56px;
  padding-bottom: 30px;
}

.columns {
  display: flex;
  width: 100%;
  gap: 30px;

  .columns__column--side {
    width: 370px;
  }

  .columns__column--content {
    flex-grow: 1;
  }
}

@media only screen and (min-width: 1610px) {
  .main {
    margin: 0 calc((100vw - 1480px) / 2);
  }
}

// @media only screen and (min-width: 2000px){
//   .main {
//     background-color: var(--grey900);
//     padding: 0 calc((100vw - 1510px) / 2);
//     margin: 0;
//   }
// }

.cky-btn-revisit-wrapper.cky-btn-revisit-wrapper {
  width: 30px;
  height: 30px;
}

.cky-btn-revisit-wrapper.cky-btn-revisit-wrapper .cky-btn-revisit {
  display: flex;
}

.cky-btn-revisit-wrapper.cky-btn-revisit-wrapper .cky-btn-revisit img {
  width: 20px;
}

.cky-footer-wrapper .cky-prefrence-btn-wrapper + div {
  display: none;
}

.cky-accordion-header.cky-accordion-header .cky-always-active {
  color: var(--green600);
}

.cky-preference-content-wrapper.cky-preference-content-wrapper
  .cky-show-desc-btn {
  color: var(--green600) !important;
}

.cky-audit-table {
  background-color: var(--grey800) !important;
  color: var(--grey100) !important;
}

@media (max-width: 1024px) {
  .main--filters {
    padding-top: 20px;

    &.category,
    &.orders,
    &.add-on-table {
      padding-top: 0;
    }
  }
}