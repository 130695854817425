.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  padding-top: 8px;
}

.tag {
  appearance: none;

  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--grey400);

  border: 1px solid var(--grey600);
  background: transparent;
  cursor: pointer;

  &--selected {
    color: var(--green600);
    border-color: var(--green600);
  }
}
