.add-ons-managers__autocomplete-container {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 15px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.add-ons-managers__autocomplete.add-ons-managers__autocomplete {
  max-width: 480px;
  width: 100%;
  flex-shrink: 0;
  
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--grey500) !important;
    border-radius: 0;
  }

  .MuiOutlinedInput-input {
    color: var(--grey300);
  }

  .MuiInputLabel-root {
    color: var(--grey500);
    line-height: initial;
  }

  .MuiButtonBase-root {
    color: var(--grey500);
  }

  .MuiOutlinedInput-root {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.MuiAutocomplete-noOptions.MuiAutocomplete-noOptions {
  background-color: var(--grey700);
  color: var(--grey300);
  border-radius: 0;
}

.add-ons-managers__button {
  width: 208px;
  height: 48px;
}

.add-ons-managers__loading {
  color: var(--grey500);
}
