.text-wrapper {
    // display: flex;
    // flex-direction: column;
    text-align:justify;
    margin: 30px 15px;
    color: var(--grey300);
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;

    h2 {
        font-size: 48px;
        line-height: 42px;
        color: var(--grey100);
        font-weight: 300;
    }

    h3 {
        font-size: 36px;
        font-weight: 300;
        display: inline;
        color: var(--grey100);
        margin-bottom: 40px;
        margin-top: 40px;
    }

    li {
        /* Убираем нумерацию */
        list-style-type: none !important;
        /* Оставляем нумерацию в браузере IE6 и ниже */
        list-style-type: decimal;
    }
    
    /* Инициируем счетчик */
    ol {
        counter-reset: list1;

        li {
            margin-bottom: 10px;
        }
    }

    ol.text-wrapper__ol--main > li {
        margin-bottom: 40px;
    }
    
    ol li::before {
        /* Увеличиваем значение счетчика */
        counter-increment: list1;
        /* Выводим значение */
        content: counter(list1) ". ";
        font-size: 36px;
        font-weight: 300;
    }
    
    ol ol {
        /* Инициируем счетчик вложенного списка */
        counter-reset: list2;
    }
    
    ol ol li::before {
        /* Увеличиваем значение счетчика */
        counter-increment: list2;
        /* Выводим значение типа 2.1, 2.2,... */
        content: counter(list1) "." counter(list2) ". ";
        font-size: 18px;
        font-weight: 300;
    }

    ol ol ol{
        /* Инициируем счетчик вложенного списка */
        counter-reset: list3;
    }

    ol ol ol li::before {
        /* Увеличиваем значение счетчика */
        counter-increment: list3;
        /* Выводим значение типа 2.1, 2.2,... */
        content: counter(list1) "." counter(list2) "." counter(list3) ". ";
    }

    a {
        color: var(--green600);
    }
}

@media only screen and (min-width: 1610px){
    .text-wrapper {
      margin: 30px calc((100vw - 880px) / 2);;
    }
  }
