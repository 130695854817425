.mobile__filters-container {
  display: none;
}

@media (max-width: 1024px) {
  .mobile__filters-container {
    display: flex;
    background-color: var(--grey800);
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 20px;

    .mobile__filters-button {
      height: 40px;
      width: 50%;
      box-shadow: none;
      border-radius: 0;
      background-color: var(--grey800);
      border: none;
      color: var(--grey100);
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
}