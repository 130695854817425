.settings-edit__form {
  margin-top: 24px;
}

.settings-edit__contacts {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  column-gap: 30px;
}

.settings-edit__save-button.settings-edit__save-button {
  gap: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  padding: 8px 62px 8px 62px;

  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  text-align: center;
  color: white;

  background-color: var(--green600);
  border-radius: 0;

  &:hover {
    background-color: var(--green700);
  }

  &:active {
    background-color: var(--green800);
  }

  &:disabled {
    color: var(--grey100);
    background-color: var(--grey300);
    cursor: initial;
  }  
}