.cart {
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  color: var(--grey100);

  background-color: var(--grey800);
}

.cart__anchor-button.cart__anchor-button {
  color: var(--grey100);

  .cart__anchor-button-count .MuiBadge-badge {
    background-color: var(--orange700);
  }
}

.cart__header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;
}

.cart__heading {
  font-size: 34px;
  font-weight: 700;
}

.cart__close-btn.cart__close-btn {
  color: var(--grey100);
}

.cart__contents {
  margin-bottom: 32px;
  padding-right: 8px;
  max-height: 500px;
  overflow-y: auto;

  scrollbar-color: var(--green500);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: var(--green500);
  }

  .add-on-table__list-container {
    padding: 0;
  }
}

.cart__subtotal {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;

  font-size: 20px;
}

.cart__subtotal-header {
  text-transform: uppercase;
}

.cart__subtotal-hint {
  font-size: 12px;
  text-transform: none;
}

.cart__checkout.cart__checkout {
  min-width: 180px;
  align-self: end;

  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green600);
  color: var(--white);
  font-weight: 700;
  font-size: 18px;
  gap: 10px;
  border-radius: 0;
  transition: 0.2s;
  text-transform: none;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--green700);
  }

  &:disabled {
    background-color: var(--grey300);
  }
}

.cart__placeholder {
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  font-size: 16px;
}

.cart__mobile-tip.cart__mobile-tip {
  display: none;
}

@media (max-width: 1024px) {
  .cart {
    width: 100%;
    padding: 10px 15px;

    .purchased-add-on__image.purchased-add-on__image {
      width: 100px;
      height: 75px;
      display: flex;

      img {
        width: 100%;
        height: auto;
      }
    }

    .purchased-add-on__wrapper.purchased-add-on__wrapper {
      box-shadow: none;
    }

    .add-on-table__element {
      width: 100%;
      min-height: 75px;
    }
  }

  .cart__checkout.cart__checkout {
    pointer-events: none;
    color: var(--grey200);
    background-color: var(--greygreen700);
  }

  .cart__mobile-tip.cart__mobile-tip {
    display: flex;
    margin-top: 16px;
  }
}