.autocomplete {
  font-weight: 300;
  color: var(--grey400);
  border-radius: 0;

  .MuiInputBase-root {
    color: var(--grey500);

    .MuiInputBase-input {
      color: var(--grey500);
    }

    &.MuiInputBase-root:hover {
      color: var(--grey400);
    }

    &.MuiInputBase-root.Mui-error {
      color: var(--errorBg);
    }

    .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
      border-color: currentColor;
      border-radius: 0;
    }
    &.Mui-focused:not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
        border-color: var(--green600);
      }
    }
  }

  .MuiChip-root {
    background-color: transparent !important;
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    height: 32px;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid var(--grey600);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 0;
  }
}

.MuiAutocomplete-paper.MuiAutocomplete-paper {
  border-radius: 0;
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-listbox {
    background-color: var(--grey700);
    color: var(--grey300);
    border-radius: 0;
    // padding: 0;
  }

  .MuiAutocomplete-loading {
    background-color: var(--grey700);
    color: var(--grey300);
    border-radius: 0;
  }
  .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
    background-color: var(--grey800);
    color: var(--white);
  }

  .MuiBox-root {
    border-radius: 0;
  }

  .MuiAutocomplete-option.MuiAutocomplete-option {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.autocomplete__delete-icon {
  width: 14px;
  height: 14px;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 14px;
    height: 1px;
    background-color: var(--grey300);
    left: 1px;
    top: 6px;
    transition: 0.2s;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }

  &:hover,
  &:focus {
    &::before,
    &::after {
      background-color: var(--white);
    }
  }
}
