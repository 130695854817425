.add-ons-managers__wrapper {
  margin-top: 30px;
  max-width: 720px;
}

.add-ons-managers__text {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: var(--grey300);
}

.add-ons-managers__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.add-on-managers__title {
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: var(--grey200);
}
