.add-on-status {
  color: var(--grey100);
  text-transform: uppercase;
  font-size: 20px;
  width: 180px;
  top: 0;
  height: 84px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  background-color: var(--draftBg);
  border-left: 8px solid var(--draft);

  &.add-on-status--draft {
    background-color: var(--draftBg);
    border-left: 8px solid var(--draft);
  }

  &.add-on-status--moderation {
    background-color: var(--moderationBg);
    border-left: 8px solid var(--moderation);
  }

  &.add-on-status--published {
    background-color: var(--publishedBg);
    border-left: 8px solid var(--published);
  }
  
  &.add-on-status--error {
    background-color: var(--error);
    border-left: 8px solid var(--errorBg);
  }
  
  &.add-on-status--banned {
    background-color: var(--bannedBg);
    border-left: 8px solid var(--banned);
  }
  
  &.add-on-status--archived {
    background-color: var(--archivedBg);
    border-left: 8px solid var(--archived);
  }
  
  &.add-on-status--revision {
    background-color: var(--revisionBg);
    border-left: 8px solid var(--revision);
  }
}
