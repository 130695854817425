.unigine-radio-group {
  .MuiRadio-root {
    padding: 0;
    margin-right: 6px;
    color: var(--grey600);

    &.Mui-checked {
      color: var(--green600);
    }

    &.Mui-disabled {
      color: var(--grey700);
    }
  }

  .MuiFormControlLabel-label {
    font-weight: 300;
    color: var(--grey400);
    &.Mui-disabled {
      color: var(--grey500) !important;
    }
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }

  .MuiFormControlLabel-root {
    margin-left: -1px;
    margin-right: 0;
  }
}
