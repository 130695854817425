.add-on-orders__wrapper {
  margin-top: 45px;

  .add-on-table__title {
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    color: var(--grey200);
  }

  .add-on-table__column--side {
    margin-top: 0;
  }

  .filter-form__header {
    height: 42px;
    margin-bottom: 20px;
  }

  .filter-form__search {
    margin-top: 0;
  }

  .filter-form__title {
    align-items: center;
  }
}