.publisher-review-reply {
  margin-top: 24px;

  display: flex;
  align-items: flex-start;
  gap: 20px;

  font-weight: 300;
  color: var(--grey300);
}

.publisher-review-reply__icon {
  flex-shrink: 0;
}

.publisher-review-reply__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.publisher-review-reply__text {
  line-height: 24px;
}

.publisher-review-reply__btn {
  min-width: 140px;
}

.publisher-review-reply__date {
  font-weight: 400;
}

.publisher-review-reply__text {
  line-height: 24px;
}
