.docs__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-top: 66px;
  margin-bottom: 66px;
  gap: 24px;
}

.docs__title.docs__title {
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: var(--grey200);
}

.docs-last-title.docs-last-title {
  margin-top: 55px;
}

.docs__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px; 
  text-transform: uppercase;
  color: #DBE0E4;
}

.docs__subtitle--secondary {
  margin-top: 30px;
}

.docs__link {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: var(--green600);
  display: flex;
  gap: 10px;

  &:hover,
  &:focus {
    color: var(--green800);
  }

  &:active {
    color: var(--green500);
  }

  svg {
    flex-shrink: 0;
  }
}

.docs__columns {
  display: flex;
  gap: 30px;
  width: 100%;
}

.docs__column {
  background-color: var(--grey800);
  width: calc((100% - 60px) / 3);
  padding: 24px;

  .docs__link {
    margin-bottom: 16px;
  }

  .docs__subtitle {
    margin-bottom: 16px;
    font-size: 24px;
  }

  .docs__subtitle--secondary {
    font-size: 20px;
  }
}

.docs__icon--doc {
  width: 20px;
  height: 24px;
}

.docs__icon--quick-guide {
  width: 24px;
  height: 30px;
}

@media (max-width: 1024px) {
  .docs__columns {
    flex-direction: column;
  }

  .docs__column {
    width: 100%;
  }
}