.search-autocomplete {
  background-color: var(--white);
}

.search-wrapper.search-wrapper {
  padding: 1px 2px;
  display: flex;
  align-items: center;
  max-width: 370px;
  height: 40px;
  border: 1px solid var(--grey500);
  background-color: var(--grey800);
  &--purchased {
    width: 250px;
    margin-bottom: 10px;
  }
}

.search-input.search-input {
  margin-left: 10px;
  flex: 1;
  color: var(--grey300);
  font-size: 18px;
}
