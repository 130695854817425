.add-on-section__package-container {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: var(--grey300);
  display: flex;
  max-width: 620px;
  justify-content: space-between;
  flex-grow: 1;
}

.add-on-section__package-tip {
  max-width: 560px;
  margin-top: 20px;
}

.add-package__file {
  display: none;
}

.add-package__label {
  align-items: center;
  background-color: var(--green600);
  border-radius: 0;
  color: var(--white);
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  text-transform: none;
  transition: 0.2s;
  width: 188px;
  font-size: 18px;
  gap: 8px;
  height: 32px;
  line-height: 21px;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--green700);
  }

  .MuiCircularProgress-root {
    width: 18px !important;
    height: 18px !important;
  }

  &.add-package__label--disabled {
    cursor: default;
    background-color: var(--greygreen700);
    color: var(--grey200);
  }
}

.add-package__progress-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.add-package__error-container {
  font-size: 14px;
  color: var(--error);
  min-width: 188px;
}

.add-package__percent {
  text-align: center;
  min-width: 32px;
  display: inline-block;
}

.add-package__progress {
  flex-grow: 1;

  &.MuiLinearProgress-root {
    background-color: var(--grey900);

    .MuiLinearProgress-bar {
      background-color: var(--green600);
    }
  }
}

.add-package__wrapper {
  display: flex;
  gap: 25px;
  align-items: center;
}

.add-package__processing-container {
  display: flex;
  background-color: var(--orange700);
  min-width: 188px;
  min-height: 32px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: var(--grey200);

  img {
    width: 15px;
    height: 20px;
  }
}

.add-package__image--none {
  display: none;
}

.add-on-packages__container {
  width: 100%;
}

.add-on-packages__container--loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-on-packages__button--add-package {
  width: 188px;
  height: 48px;
  margin-bottom: 20px;
}