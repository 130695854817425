.unigine-tooltip {
  .unigine-tooltip__popup {
    padding: 8px 24px;

    font-size: 16px;
    font-weight: 400;
    color: inherit;

    background-color: var(--green100);
    border-radius: 4px;
    box-shadow: 0px 16px 18px rgba(0, 9, 19, 0.16);
  }

  .unigine-tooltip__popup-arrow {
    color: var(--green100);
  }

  .pre-wrap {
    white-space: pre-wrap;
  }

  .max-w-400 { 
    max-width: 400px;
  }
}
