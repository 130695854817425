.unigine-tip {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  padding: 24px;
  padding-left: 16px;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--grey800);
  background-color: var(--orange400);

  &--compact {
    padding: 8px;
    padding-left: 16px;
  }

  .unigine-tip__text {
    align-self: center;
  }

  &::before {
    content: "";
    display: block;

    flex-grow: 0;
    min-width: 24px;
    width: 24px;
    height: 24px;
    background-image: url("/app/assets/icon_info.svg");
  }

  a {
    color: var(--greygreen800);
  }

  p:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .unigine-tip {
    padding: 10px;
  }
}