.purchase-button {
  gap: 8px;
  max-width: 240px;
  width: 208px;
  height: 48px;
  padding: 4px 18px 4px 18px;

  &.purchase-button--unavailable,
  &.purchase-button--unavailable:disabled {
    display: flex;
    justify-content: flex-end;
    min-width: 360px;
    padding: 0;
    font-weight: 400;

    color: var(--grey500);
    text-transform: uppercase;
    background: none;

    @media (max-width: 1024px) {
      min-width: 200px;
    }
  }

  &.purchase-button--in-cart {
    color: var(--grey200);
    background-color: var(--greygreen700);
  }
}
