.instant-purchase-dialog.instant-purchase-dialog {
  padding: 0;

  background-color: var(--grey700);
  color: var(--grey100);

  @media (max-width: 768px) {
    padding: 16px;
    width: calc(100% - 16px);
  }

  .instant-purchase-dialog__title {
    padding: 32px;
    margin-bottom: 0;
    font-size: 60px;
    font-weight: 300;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  .ordered-item {
    box-sizing: content-box;
    padding: 32px;
  }

  .ordered-item__info {
    padding-right: 0;
  }
}
