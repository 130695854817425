.page-title {
  font-size: 36px;
  text-transform: uppercase;
  color: var(--orange400);
}

.unigine-link {
  color: var(--green700);

  &:hover {
    color: var(--green500);
  }

  &:active {
    color: var(--green800);
  }
}

.text-format {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  word-break: break-word;
  // richtext headings goes from h2
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 40px;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 8px;
  }

  a {
    color: var(--green700);
    word-break: break-word;

    &:hover {
      color: var(--green500);
    }

    &:active {
      color: var(--green800);
    }
  }

  pre {
    margin-bottom: 10px;
    color: var(--grey100);
    padding: 5px;

    white-space: break-spaces;
    word-break: break-word;

    code {
      font-size: 14px;
    }
  }

  strong {
    font-weight: 500;
  }

  em,
  i {
    font-style: italic;
  }

  blockquote {
    padding: 10px;
    margin: 0 0 10px;
    font-style: italic;
    border-left: 3px solid var(--grey600);
    background-color: var(--grey700);

    p:last-child {
      margin-bottom: 0;
    }
  }

  ol,
  ul {
    padding-left: 2em;
    margin-bottom: 10px;
  }

  ol {
    list-style: decimal;

    ol {
      list-style: lower-latin;

      ol {
        list-style: lower-roman;
      }
    }
  }

  ul {
    list-style: disc;

    ul {
      list-style: circle;

      ul {
        list-style: square;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  code:not([class^="language"]) {
    display: inline-block;
    border-radius: 3px;
    padding: 1px 2px;
    vertical-align: bottom;
  }

  // fix youtube media display
  figure.media {
    max-width: 720px;
    & > div > div {
      position: relative;
      height: 0;
      padding-bottom: 56.2493%;
    }
  }
}

.text-format code:not([class^="language"]) {
  color: #abb2bf;
  background-color: #282c34;
}

svg:not(.svg-color):not(.MuiCircularProgress-svg) * {
  fill: currentColor;
}

@media (max-width: 1024px) {
  .text-format {
    font-size: 16px;
    line-height: 22px;
  }
}
