.package-status {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 10px;

  &--processing {
    background-color: var(--error);
  }

  &--error {
    background-color: var(--errorBg);
  }
}
