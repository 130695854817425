.orders {
  display: flex;
  flex-direction: column;
  color: var(--grey100);
}

.orders__heading {
  font-weight: 300;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  color: var(--grey200);
}

.orders__columns {
  display: flex;
  flex-grow: 1;
  gap: 30px;
}

.orders__filters {
  width: 370px;
  margin-top: 18px;
}

.orders__table {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.orders__sort {
  align-self: center;
}

.orders__spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.orders__list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.orders__pagination {
  margin-top: auto;
  align-self: center;

  a {
    color: var(--grey100);

    &:hover,
    &:focus,
    &.Mui-selected:hover,
    &.Mui-selected:focus,
    &.Mui-selected {
      background-color: var(--green600);
    }
  }
}

.order {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 24px;

  color: var(--grey100);
  text-decoration: none;
  background-color: var(--grey700);
}

.order__status {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-right: auto;
  min-width: 260px;

  font-size: 20px;
  text-transform: uppercase;

  &::before {
    content: "";
    width: 40px;
    height: 40px;
    background-position: 0 50%;
    background-repeat: no-repeat;
  }

  &--completed::before {
    background-image: url("/app/assets/order-status-completed.svg");
  }
  &--canceled::before {
    background-image: url("/app/assets/order-status-cancelled.svg");
  }
  &--refunded::before {
    background-image: url("/app/assets/order-status-refunded.svg");
  }
  &--not_paid::before {
    background-image: url("/app/assets/order-status-unpaid.svg");
  }
  &--pending_payment::before {
    background-image: url("/app/assets/order-status-unpaid.svg");
  }
}

.order__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: auto;

  font-size: 18px;
}

.order__price-wrapper {
  min-width: 110px;
  text-align: right;
  display: flex;
  flex-direction: column;
}

.order__price {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;

  &--free {
    color: var(--green500);
  }

  &--sale {
    color: var(--orange400);
  }
}

.order__base-price {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: line-through;
  color: var(--grey400);
  text-align: right;
}

.order__action {
  padding: 16px;
  min-width: 160px;
  min-height: 48px;

  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  color: var(--grey900);

  background-color: var(--grey300);
  border: none;

  cursor: pointer;

  margin-left: 40px;

  &:hover {
    background-color: var(--grey200);
  }

  &:active {
    background-color: var(--grey400);
  }
}

.orders__mobile-tip.orders__mobile-tip {
  display: none;
}

@media (max-width: 1024px) {
  .title-wrapper {
    .orders__heading {
      font-size: 20px;
      line-height: 30px;
    }

    .sort-control {
      display: none;
    }
  }

  .orders__filters {
    display: none;
  }

  .order {
    flex-wrap: wrap;
    padding: 10px 12px;
    pointer-events: none;
  }

  .orders__mobile-tip.orders__mobile-tip {
    display: flex;
  }

  .order__info {
    order: -2;
    font-size: 14px;
    gap: 5px;
  }

  .order__price-wrapper {
    order: -1;
  }

  .order__action {
    display: none;
  }
}