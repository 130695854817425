.add-on-infobox {
  display: flex;
  gap: 32px;
}

.add-on-sticky-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 60px;
  padding: 16px;
  z-index: 10;

  display: flex;
  justify-content: center;

  background-color: var(--grey700);

  @media (max-width: 1024px) {
    display: none;
  }

  .add-on-infobox--sticky {
    flex-grow: 1;
    max-width: 1440px;

    .add-on-infobox__image {
      width: 162px;
      height: 95px;
    }

    .add-on-infobox__price {
      flex-direction: row;
      justify-content: end;
      gap: 24px;
    }

    .add-on-infobox__purchase-btn-wrapper {
      margin-top: 16px;
    }

    .add-on-infobox__purchase-btn.purchase-button--unavailable {
      margin-top: 8px;
    }

    .purchased-notice {
      margin-top: 16px;
      line-height: 1;
    }
  }
}

.add-on-infobox__image {
  width: 228px;
  height: 128px;
  object-fit: cover;

  @media (max-width: 1024px) {
    // width: calc(50% - 6px);
    // height: auto;
  }
}

.add-on-infobox__details {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 1024px) {
    // order: -1;
    // margin-bottom: 10px;
  }
}

.add-on-infobox__title {
  max-height: 64px;

  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.15px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.add-on-infobox__price {
  margin-inline-start: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1024px) {
    // width: calc(50% - 6px);

    .price-display__price {
      margin-top: 0;
      font-size: 24px;
    }

    .purchased-notice {
      font-size: 24px;
      text-align: right;
      align-self: flex-end;
    }

    .purchase-button {
      width: 160px;
      align-self: flex-end;
    }
  }
}

.add-on-infobox__price-display {
  margin-top: 11px;
}

.add-on-infobox__purchase-btn-wrapper {
  pointer-events: all;
}
