.review__container {
  display: flex;
  flex-direction: column;
}

.review__header {
  display: flex;
  margin-bottom: 10px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;

    .review__actions {
      margin-top: 10px;
    }
  }
}

.review__user-image-container {
  width: 40px;
  height: 40px;
  margin-right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.review__user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.review__header-info-container {
  display: flex;
  width: calc(100% - 65px);
  flex-direction: column;
}

.review__user-info {
  display: flex;
  margin-bottom: 10px;
  line-height: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 8px;
  }
}

.review__user-name {
  color: var(--grey300);
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 20px;

  &.review__user-name--own {
    text-transform: initial;
    margin-right: 5px;
  }
}

.review__created {
  margin-right: 12px;
  font-weight: 400;
  font-size: 16px;
  color: var(--grey300);
}

.review__status-badge {
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  line-height: 1;
  text-transform: capitalize;

  &--published {
    background-color: rgba($color: #629438, $alpha: 0.45);
  }

  &--being-checked::before,
  &--banned::before {
    content: "";
    width: 12px;
    height: 12px;
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  &--being-checked {
    background-color: var(--orange700);
    &::before {
      background-image: url("/app/assets/icon_hourglass.svg");
    }
  }

  &--banned {
    background-color: rgba($color: #d43e00, $alpha: 0.45);
    &::before {
      background-image: url("/app/assets/icon_error_transparent.svg");
    }
  }
}

.review__rating-container {
  display: flex;
  gap: 20px;
}

.review__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--grey300);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.review__title-container {
  overflow: hidden;
}

.review__main {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: var(--grey300);
  margin-left: 65px;
  margin-bottom: 10px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.review__main--own {
  margin-bottom: 0;
}

.review__footer {
  margin-left: 65px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.review__body {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;

  &.review__body--full-text {
    display: block;
  }
}

.review__help {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--grey300);
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.review__thumbs-button {
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  color: var(--grey200);

  &.review__thumbs-button--disabled {
    cursor: default;
  }
}

.review__thumb-up--liked path {
  fill: var(--green600);
}

.review__report-icon path {
  fill: var(--grey200);
}

.review__report-icon {
  width: 16px;
  height: 16px;
}

.review__report {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--grey300);
  display: flex;
  gap: 10px;

  &--reported {
    color: var(--green600);
    .review__report-icon path {
      fill: var(--green600);
    }

    .review__thumbs-button {
      cursor: default;
    }
  }

  &--guest {
    color: var(--grey500);
    .review__report-icon path {
      fill: var(--grey500);
    }
    .review__thumbs-button {
      cursor: default;
    }
  }
}

.review__footer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.review__answer {
  margin-left: 65px;
  margin-top: 20px;

  header {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
  }

  main {
    margin-left: 40px;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: var(--grey300);
  }
}

.review__header-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--grey300);

  .review__publisher-name {
    text-transform: uppercase;
    color: inherit;
    text-decoration: none;
  }
}

.review__thumbs-container {
  top: 2px;
  position: relative;
  margin-left: 5px;
}
