.review-card__wrapper {
  display: flex;
  flex-direction: column;
}

.review-card__header {
  display: flex;
  align-items: center;
}

.review-card__addon-title {
  margin-left: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--grey100);
}

.review-card__button-wrapper {
  display: flex;
  gap: 16px;
  margin-left: auto;
  //justify-self: start;
}

.review-card__action {
  min-width: 142px;
}

.review-card__autor {
  margin-top: 20px;
  display: flex;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--grey300);

  .autor-name {
    text-transform: uppercase;
  }
}

.review-card__title-wrapper {
  margin-top: 18px;
  display: flex;
  gap: 20px;

  .review-card__title {
    color: var(--grey300);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 580px;
  }
}

.report-card__text {
  margin-top: 18px;
  color: var(--grey300);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  white-space: pre-line;
}
