.pagination__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px 32px;
}

.pagination__select-container {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;

  .MuiInputLabel-root {
    color: var(--grey100);
  }
}

.pagination {
  div {
    color: var(--grey100);
  }

  a {
    color: var(--grey100);

    &:hover,
    &.Mui-selected:hover,
    &.Mui-selected {
      background-color: var(--green600);
    }
  }
}

.pagination-select.pagination-select {
  max-width: 64px;

  &.MuiInputBase-root {
    border: 1px solid var(--grey100);
  }

  .MuiInputBase-input {
    min-width: 0;
    color: var(--grey100);
  }

  .MuiSelect-icon {
    color: var(--grey100);
  }
}
