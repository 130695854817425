.category {
  background-color: var(--grey900);
  color: var(--grey100);
}

.category__wrapper {
  display: flex;
  width: 100%;
}

.category__column {
  display: flex;
  flex-direction: column;

  &--main {
    flex-grow: 1;
  }

  &--side {
    width: 370px;
    margin-top: 18px;
  }
}

.pagination__container {
  display: flex;
  justify-content: center;
}

.category__title {
  text-transform: uppercase;
  color: var(--grey100);
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  margin-bottom: 10px;
}

.category__title-wrapper {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .category__title-wrapper {
    .category__title {
      font-size: 20px;
      line-height: 30px;
    }

    .sort-control {
      display: none;
    }
  }

  .category__column--side {
    display: none;
  }
}
