.contact-box {
  background-color: var(--grey800);
  padding: 20px;
  width: 100%;
  height: 144px;
  margin-top: 40px;

  .contact-box__title {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--grey300);
  }

  .contact-box__site {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: var(--grey300);
    text-decoration: none;
  }
  
  .contact-box__social-list {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .contact-box__social-item {
    //margin-right: 25px;
    button {
      padding: 0px;

      &:disabled {
        img {
          opacity: 0.5;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .contact-box__social-link {
    color: var(--black);
    // width: 50px;
    // height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }
}