.footer {
  background-color: var(--black000);
  padding-top: 20px;
  min-height: 240px;
  z-index: 5;
}

.footer__top-container {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  //position: relative;
  margin-bottom: 60px;
}

.footer__bottom-container {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.header__navigation {
  display: flex;
}

.navigation__list {
  display: flex;
  align-items: center;
}

.navigation__item-footer {
  margin-right: 66px;

  &:last-child {
    margin-right: 0;
  }
}

.navigation__item-footer_bottom {
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}

.navigation__link-footer {
  color: var(--grey100);
  font-size: 20px;
  text-transform: none;
  text-decoration: none;
}

.logo-footer {
  color: var(--grey100);
  margin-right: 84px;
}

.navigation--top {
  margin-right: 180px;
}

.unigine-rights {
  color: var(--grey300);
  padding-bottom: 30px;
  padding-left: 15px;
  font-size: 14px;
}

.footer__line {
  border: 1px solid var(--grey600);
}

.footer__channels-text {
  color: var(--grey100);
  margin-right: 20px;
}

.footer__bottom-container .footer__navigation {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1024px){
  .footer__top-container {
    padding: 10px 15px;
  }
  .footer__bottom-container {
    padding: 23px 15px;
  }
  .unigine-rights {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 1610px){
  .footer__top-container {
    padding: 10px calc((100vw - 1480px) / 2);;
  }
  .footer__bottom-container {
    padding: 23px calc((100vw - 1480px) / 2);;
  }
  .unigine-rights {
    padding-left: calc((100vw - 1480px) / 2);;
  }
}

@media only screen and (min-width: 2000px){
  .footer__top-container {
    max-width: 100%;
    margin: auto;
    margin-bottom: 60px;
    padding: 10px calc((100vw - 1480px) / 2);
  }
  .footer__bottom-container {
    max-width: 100%;
    margin: auto;
    padding: 23px calc((100vw - 1480px) / 2);
  }
  .unigine-rights {
    max-width: 100%;
    margin: auto;
    padding-left: calc((100vw - 1480px) / 2);
  }
}

@media (max-width: 1024px) {
  .footer {
    .footer__navigation.footer__navigation {
      display: block;
      margin-top: 20px;
  
      .navigation__list {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .navigation__item-footer {
      margin-right: 0;
      margin-bottom: 5px;
    }

    .navigation__link-footer {
      font-size: 16px;
    }
  
    .footer__top-container {
      display: block;
      margin-bottom: 10px;
    }

    .unigine-rights {
      text-align: center;
      padding-right: 15px;
    }

    .footer__bottom-container {
      .footer__navigation.footer__navigation {
        margin-top: 0;
      }

      .navigation__list.navigation__list {
        flex-direction: row;
      }
    }

    .footer__channels-text {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}