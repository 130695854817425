.dashboard__title {
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: var(--grey200);
}

.dashboard__list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.dashboard__subtitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: var(--grey200);
  margin-bottom: 12px;
}

.dashboard__section {
  margin-top: 60px;
}

.dashboard__value {
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 50px;

  &--income {
    color: var(--green500);
  }

  &--refunds {
    color: var(--grey500);
  }
}

.MuiSkeleton-root.MuiSkeleton-root {
  background-color: var(--grey700);
}

.dashboard__error {
  color: var(--grey100);
  margin-top: 40px;
}