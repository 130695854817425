.purchased-item__purchase-info {
  min-width: 400px;
  height: 100%;
  padding: 8px 24px 8px 0;
  display: flex;
  align-items: center;
  gap: 32px;

  font-size: 16px;
  line-height: normal;
}

.purchased-item__purchase-details {
  align-self: stretch;

  display: grid;
  place-content: center start;
  gap: 4px;
  padding-left: 16px;

  border-left: 1px solid var(--grey600);
}

.purchased-item__details-link.purchased-item__details-link {
  flex-basis: 180px;
  flex-shrink: 0;
  margin-left: auto;
  padding: 16px;

  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  color: var(--white);

  background-color: var(--green600);
  border: none;
  border-radius: 0;

  &:hover {
    background-color: var(--green700);
  }

  &:active {
    background-color: var(--green800);
  }
}

@media (max-width: 1024px) {
  .puchased-item {
    --extras-size: 0fr;
  }

  .purchased-item__purchase-info {
    display: none;
  }
}
