.content-container--404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error404__title.error404__title {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  color: var(--grey200);
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 500;
}

.error404__text.error404__text {
  color: var(--grey200);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  width: 800px;
  font-size: 28px;
  font-weight: 300;

  .text-link {
    display: contents;
    color: var(--green500);
  }
}

.error404__link {
  display: flex;
  justify-content: center;
  color: var(--green500);
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 30px;
}