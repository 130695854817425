.add-on-table {
  display: flex;
  flex-direction: column;
  color: var(--grey100);
}

.add-on-table__title {
    text-transform: uppercase;
    color: var(--grey100);
    font-size: 48px;
    font-weight: 300;
    line-height: 58px;
}

.add-on-table__column {
    display: flex;
    flex-direction: column;
  
    &--main {
      flex-grow: 1;
    }
  
    &--side {
      width: 370px;
      flex-shrink: 0;
      margin-top: 18px;
    }
}

.add-on-table__sortby {
  display: flex;
  align-items: center;
  color: var(--grey300);
  font-size: 18px;
  font-weight: 400;
}

.add-on-table__sortby--sign.add-on-table__sortby--sign {
  color: var(--grey300);
  font-size: 24px;
  font-weight: 400;
  position: absolute;
  top: 25px;
  left: -80px;
  &.Mui-focused.Mui-focused {
    color: var(--grey300);
  }
}

.add-on-table__sortby-select.add-on-table__sortby-select {
  min-width: 120px;
  border: 1px solid var(--grey500);
  color: var(--grey300);
  height: 40px;
  width: 175px;
  border-radius: 0;
  margin-right: 80px;
  margin-top: 10px;
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiSvgIcon-root {
    color: var(--grey300);
  }
}

.title-wrapper {
  display: flex;
}

@media (max-width: 1024px) {
  .title-wrapper {
    .add-on-table__title {
      font-size: 20px;
      line-height: 30px;
    }

    .sort-control {
      display: none;
    }
  }

  .add-on-table__column--side {
    display: none;
  }
}