.refund-card {
  display: flex;
  gap: 20px;
}

.refund-select-all {
  font-size: 14px;
  color: var(--grey300);
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 16px;
  gap: 30px;

  .MuiFormControlLabel-label.Mui-disabled.Mui-disabled {
    color: var(--grey600);
  }
}

.refund-button__wrapper {
  display: flex;
  justify-content: flex-end;
}

.refund-button.refund-button {
  background-color: var(--green600);
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  border-radius: 0px;
  gap: 8px;
  text-transform: none;
  margin-top: 26px;
  padding: 10px 26px;

  &:hover {
    background-color: var(--green700);
  }

  &:active {
    background-color: var(--green800);
  }

  &:disabled {
    color: var(--grey100);
    background-color: var(--grey300);
    cursor: initial;
  }
}

.ordered-add-on__price-refund {
  align-self: center;
  margin-inline-end: 28px;

  font-size: 20px;
  text-transform: uppercase;
}