.add-on-table__container {
    display: flex;
    margin-bottom: 40px;
  
    &:last-child {
      margin-bottom: 0;
    }
}

.add-on-table__list-container {
    padding: 20px 0px 40px;
    width: 100%;
}

.add-on-table__list {
    display: grid;
    //grid-template-columns: repeat(auto-fit, 300px);
    gap: 20px;
    justify-items: stretch;
    align-items: stretch;
}

.add-on-table__loading-container {
    display: flex;
    color: var(--grey400);
    justify-content: center;
    margin-top: 50px;
}

.add-on-table__no-add-ons {
    color: var(--grey400);
    text-transform: uppercase;
    font-size: 24px;
}

.add-on-table__element {
    background-color: var(--grey700);
    min-height: 96px;
}