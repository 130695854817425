.checkbox-group {
  .checkbox-group__items {
    align-items: flex-start;

    .checkbox-group__item .MuiFormControlLabel-label {
      font-weight: 300;
      text-transform: capitalize;
      color: var(--grey400);
      &.Mui-disabled {
        color: var(--grey500);
      }
    }

    .checkbox-group__checkbox {
      margin-right: 6px;
    }

    .checkbox-group__item {
      margin-left: -4px;
    }
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }
}
