.add-on-section__admin-package {
  padding-top: 25px;
}

.add-on-section__package-container {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: var(--grey300);
  display: flex;
  max-width: 620px;
  justify-content: space-between;
  flex-grow: 1;
  gap: 20px;
}

.add-package__processing-container--is-processed {
  color: var(--grey300);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 15px;
  min-height: 32px;
  flex-shrink: 0;
}

.add-on-section__download-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 188px;
  height: 32px;
  background-color: var(--grey200);
  color: var(--grey900);

  &:hover,
  &:focus {
    background-color: var(--grey300);
  }

  &:active {
    background-color: var(--grey200);
  }
  &:disabled {
    background-color: var(--grey300) !important;
    color: var(--white) !important;
    cursor: default;
  }
}

.download-button-disabled {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.add-on-packages__admin {
  margin-top: 20px;
}