.price-display {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.price-display__base-price {
  font-size: 18px;
  line-height: 21px;
  color: var(--grey400);
  text-decoration: line-through;

  .price-display--unavailable & {
    color: var(--grey500);
  }
}

.price-display__price {
  margin-top: 21px;

  font-weight: 500;
  font-size: 36px;
  text-transform: uppercase;

  &--special {
    margin-top: 0;
    color: var(--orange400);
  }

  .price-display--unavailable & {
    color: var(--grey500);
  }
}
