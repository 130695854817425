.dialog.package-list-dialog {
  .dialog__content {
    color: var(--grey100);
  }

  .package-list-dialog__heading {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .package-list-dialog__packages {
    max-height: 540px;
    overflow-x: hidden; // hack to hide horizontal scrollbar appearing for no reason
    overflow-y: auto;

    scrollbar-color: var(--green500);

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background-color: var(--green500);
    }
  }
}
