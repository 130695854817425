.package-list__item:not(:last-child) {
  margin-bottom: 16px;
}

.package-groups {
  width: 768px;

  .package-groups__group:not(:last-child) {
    margin-bottom: 18px;
  }

  .package-groups__group-label {
    display: block;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.package {
  --lh: 22px;
  --max-lines: 4;

  padding: 24px;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  line-height: var(--lh);

  background-color: var(--grey800);

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.package__attributes {
  width: 200px;
  flex-shrink: 0;

  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 16px;
}

.package__platform {
  display: flex;
  gap: 8px;
  text-transform: capitalize;
}

.package__platform-icon {
  padding: 2px;
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: currentColor;

  svg {
    width: 100%;
    height: 100%;
  }

  * {
    fill: var(--grey800);
  }

  &--windows {
    padding: 4px;
  }
}

.package__description {
  align-self: stretch;
  flex-grow: 1;
  padding-left: 16px;
  border-left: 1px solid var(--grey600);

  @media (max-width: 1024px) {
    padding-left: 0;
    border-left: none;
  }
}

.package__description-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  &--expanded {
    display: block;
  }
}

.package__expand-btn {
  margin-top: 5px;
  padding: 0;
  font: inherit;
  color: var(--green600);
  text-decoration: underline;

  background-color: transparent;
  border: none;
  box-shadow: none;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.package__empty-description {
  color: var(--grey600);
}

.package__actions {
  flex-basis: 160px;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    flex-basis: auto;
    align-self: center;
  }
}

.package__download-btn {
  min-width: 160px;
  min-height: 40px;
  padding: 8px 24px;
  svg {
    fill: currentColor;
  }
}
