.dialog-contact__form {
  width: 100%;
}

.dialog-contact__submit {
  width: 180px;
}

.dialog-contact__button-wrapper {
  display: flex;
  gap: 20px;
  align-items: flex-end;

  .dialog-contact__success-message {
    color: var(--green600);
    size: 18px;
  }

  .dialog-contact__error-message {
    color: var(--error);
    size: 18px;
  }
}