.unigine-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 10px;

  color: var(--white);
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  text-transform: none;

  background-color: var(--green600);
  border: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: var(--green700);
  }

  &:active {
    background-color: var(--green800);
  }

  &:disabled {
    background-color: var(--grey300);
    cursor: default;
  }

  &.unigine-button--secondary {
    color: var(--grey900);
    background-color: var(--grey300);

    &:hover,
    &:focus {
      background-color: var(--grey200);
    }

    &:active {
      background-color: var(--grey400);
    }

    &:disabled {
      background-color: var(--grey200);
      color: var(--grey100);
      cursor: default;
    }
  }
}
