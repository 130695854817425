.review-form__rating {
  height: 100%;
  display: flex;
  align-items: center;
}

.review-form__title {
  margin-bottom: 32px;

  font-size: 34px;
  font-weight: 700;
  color: var(--grey100);

  @media (max-width: 1024px) {
    font-size: 24px;
  }
}

.review-form__label {
  color: var(--grey100);
}

.review-form__field.review-form__field {
  .MuiInputBase-root {
    .MuiInputBase-input {
      color: var(--grey300);
    }
  }
}

.review-form__submit {
  margin-top: 24px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
}

.review-form__error.review-form__error {
  font-size: 14px;
  color: var(--errorBg);
}

.dialog--review-form.dialog--review-form {
  @media (max-width: 1024px) {
    .MuiPaper-root {
      margin: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
    }

    .dialog__content.dialog__content {
      padding: 16px;
      overflow: auto;
    }

    .form-grid {
      display: flex;
      flex-direction: column;
    }

    .form-grid__group-label {
      margin-top: 0;
      margin-bottom: 5px;
    }
    .form-grid__group-content {
      width: 100%;
    }

    .review-form__form {
      display: flex;
      flex-direction: column;
      gap: 32px;
      height: 100%;
    }

    .review-form__submit {
      margin-top: auto;
    }
  }
}
