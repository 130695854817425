.data-list {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px 32px;

  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: var(--grey300);
}

.data-list-item__label {
  justify-self: start;
  align-self: start;
  text-transform: uppercase;
}

.data-list-item__content {
  min-width: 0;
}
