.payment-dialog__content {
  display: grid;

  height: calc(100vh - 100px);

  overflow: auto;

  &.payment-dialog__content--payout {
    height: 600px;
    background-color: var(--grey900);
  }

  .payment-dialog__frame {
    width: 100%;
    height: 100%;
  }
}

.payment-dialog--payout .MuiPaper-root {
  max-width: 770px;
  background-color: transparent;
}

.payment-dialog__content--status.payment-dialog__content--status {
  background-color: var(--grey700);
  padding: 60px 30px 40px;
}

.payment-dialog__title {
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: var(--grey200);
  margin-bottom: 20px;
}

.payment-dialog__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: var(--grey200);
  margin-bottom: 60px;

  a {
    color: var(--green600);
    transition: 0.2s;
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--green700);
    }
  }
}

.payment-dialog__action-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}