.publisher__header {
  display: flex;
  margin-bottom: 26px;

  img {
    border-radius: 50%;
  }
  //align-items: center;
}

.publisher__header-info {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}

.publisher__header-title {
  color: var(--grey100);
  text-transform: uppercase;
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
}

.publisher__header-add-ons-count {
  color: var(--grey300);
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
}

.publisher__main-content {
  display: flex;
  gap: 40px;
  margin-top: 70px;
  margin-bottom: 116px;
}

.publisher__media-wrapper {
  flex-basis: 864px;
  flex-grow: 0;
  flex-shrink: 2;
  max-width: 920px;
}

.publisher__description-wrapper {
  flex-basis: 560px;
  flex-grow: 1;
  flex-shrink: 2;
  display: flex;
  flex-direction: column;
}

.publisher__description::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--grey900);
}

.publisher__description::-webkit-scrollbar-thumb {
  background: var(--green600);
}

.publisher__description {
  font-size: 18px;
  color: var(--grey300);
  line-height: 24px;
  font-weight: 300;
  overflow-y: scroll;
  max-height: 300px;
  margin-bottom: auto;

  a {
    color: var(--green600);

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 10px;
  }

  li {
    margin-bottom: 10px;
  }

  ol,
  ul {
    margin-left: 2em;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
}

.publisher__related-title {
  font-weight: 500;
  font-size: 36px;
  color: var(--grey200);
  padding-bottom: 26px;
}

.publisher__related-wrapper {
  background-color: var(--grey800);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  //margin: 0 !important;

  &.publisher__related-wrapper--slider {
    .publisher__related-title {
      margin-right: 30px;
      margin-left: 30px;
    }
  }
}

.publisher__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}

@media (max-width: 1279px) {
  .publisher__media-wrapper--slider {
    max-width: 720px;
  }
}

@media (max-width: 1200px) {
  .publisher__media-wrapper--slider {
    max-width: 650px;
  }
}

@media (max-width: 1024px) {
  .publisher__media-wrapper--slider {
    max-width: 100%;
  }

  .publisher__main-content {
    flex-direction: column;
  }

  .publisher__description-wrapper {
    flex-basis: auto;
  }
}